@if(inputHasData)
{
  <div (click)="onCreate.emit()" class="btn btn-primary">
    <i class="pi pi-plus"></i>
    {{'@@CUSTOMER.ACTION.PROJECT_CREATE' | translate }}
  </div>

  <!-- <div (click)="onImport.emit()" class="btn btn-plane ml32">
    <i class="pi pi-upload"></i>
    {{ '@@CUSTOMER.ACTION.PROJECT_IMPORT' | translate}}
  </div> -->

  <!-- Project Table -->
  <div class="mt32">
    <cc-table
      [inputTableMapping]   = "$$ui.projectsMapping"
      [inputDataSource]     = "inputDataSource"
      [inputHasPaging]      = "true"

      (onEdit)              = "onEdit.emit($event)"
      (onDelete)            = "onDelete.emit($event)"
      (onNavigate)          = "onNavigate.emit($event)"

      (onNext)              = "onPageChange.emit($event)"
      (onPrev)              = "onPageChange.emit($event)"

      (onSearch)            = "onSearch.emit($event)">
    </cc-table>
  </div>
}

@if(!inputHasData)
{
  <div class="no-data-found">

    <i class="pi pi-file-check fs46"></i>
    <div class="fsb32 mt8">{{ '@@CUSTOMER.NO_DATA_FOUND.PROJECT_TITLE'    | translate }}</div>
    <div class="fs16 mt16">{{ '@@CUSTOMER.NO_DATA_FOUND.PROJECT_SUBTITLE' | translate }}</div>

    <div class="mt32">
        <div (click)="onCreate.emit()" class="btn btn-primary">
          <i class="pi pi-plus"></i>
          {{'@@CUSTOMER.ACTION.PROJECT_CREATE' | translate }}
        </div>

        <!-- <div (click)="onImport.emit()" class="btn btn-plane ml32">
          <i class="pi pi-upload"></i>
          {{'@@CUSTOMER.ACTION.PROJECT_IMPORT' | translate }}
        </div> -->
    </div>
  </div>
}
