import {  CommonModule  } from '@angular/common';
import {  Component     ,
          Input         } from '@angular/core';
import { TranslatePipe  } from 'nv@services/translate.service';

@Component({
  standalone  : true,
  selector    : 'template-site-location',
  templateUrl : './template-site-location.component.html',
  styleUrl    : './template-site-location.component.scss',
  imports     : [ CommonModule, TranslatePipe ]
})
export class TemplateSiteLocation {

  @Input() public inputData: any;
  @Input() public inputIsVisible = false
}
