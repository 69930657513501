import { ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';
import { FormArray, FormControl, ReactiveFormsModule } from '@angular/forms';
import { CommonModule             } from '@angular/common';
import { ActivatedRoute           } from '@angular/router';
import { distinctUntilChanged, lastValueFrom, map, switchMap, tap } from 'rxjs';

import { DropdownModule           } from 'primeng/dropdown';

import { StateCollectionService   } from 'app/@shared/collections/state.collection.service';
import { UtilityCollectionService } from 'app/@shared/collections/utility.collection.service';
import { GlobalRedirectService    } from 'app/@routes/redirect.service';
import { CcFormArray, CcFormGroup } from 'app/@shared/forms/form-group/cc-form-group.extention';
import { FormUtil                 } from 'nv@services/form-util.service';
import { TranslatePipe            } from 'nv@services/translate.service';
import { WizzardSiteDetails       } from 'nv@features/sites/@components/wizzard-site-details/wizzard-site-details.component';
import { SidebarManageReports     } from 'nv@features/@modals/manage-reports/manage-reports.component';
import { SiteApi                  } from 'nv@api/site.api.service';
import { ReportApi                } from 'nv@api/report.api.service';
import { ReportModel              } from 'nv@models/report.model';

import { WizzardSiteConfig        } from '../@components/wizzard-site-config/wizzard-site-config.component';
import { TemplateElectricityPrice } from '../@templates/template-electricity-price/template-electricity-price.component';
import { TemplatePricingDetails   } from '../@templates/template-pricing-details/template-pricing-details.component';
import { TemplateRoi              } from '../@templates/template-roi/template-roi.component';
import { TemplateSiteLocation     } from '../@templates/template-site-location/template-site-location.component';
import { TemplateUtilityService   } from '../@templates/template-utility-service/template-utility-service.component';
import { SiteState                } from './site.state';
import { SiteService              } from './site.service';
import { SiteResolverModel        } from '../site.resolver';
import { TemplatePortNumber       } from '../@templates/template-port-number/template-port-number.component';

@Component({
  standalone: true,
  selector: 'page-site',
  templateUrl: './site.component.html',
  styleUrl: './site.component.scss',
  providers: [SiteState, SiteService],
  imports: [
    ReactiveFormsModule,
    DropdownModule,
    WizzardSiteDetails,
    WizzardSiteConfig,
    CommonModule,
    SidebarManageReports,

    TemplateSiteLocation,
    TemplatePortNumber,
    TemplateUtilityService,
    TemplateElectricityPrice,
    TemplatePricingDetails,
    TemplateRoi,

    TranslatePipe,
  ],
})
export class SitePage implements OnInit {

  public globalRedirect             = inject(GlobalRedirectService);
  public $stateCollectionService    = inject(StateCollectionService);
  public $utilityCollectionService  = inject(UtilityCollectionService);
  public $$ui                       = inject(SiteState);
  public fu                         = inject(FormUtil);

  private SiteService               = inject(SiteService);
  private $siteApiService           = inject(SiteApi);
  private $reportApi                = inject(ReportApi);
  private route                     = inject(ActivatedRoute);
  private cdref                     = inject(ChangeDetectorRef);

  public $formArrayMap: any;
  public wizzardIndex = 1;
  public mapParameters: any = null;

  public siteResolver!: SiteResolverModel;
  public projectId!: string | null;

  public reportCollection: any;

  public $form = new CcFormGroup({
    roiPeriod                   : new FormControl<number | null>(null, []),
    roiAvgWeeklyVisits          : new FormControl<number | null>(null, []),
    roiNpv                      : new FormControl<number | null>(null, []),
    roiWeeklyEnergyConsumption  : new FormControl<number | null>(null, []),
    roiIsScored                 : new FormControl<boolean | null>(false, []),
    suggestedNumberOfPorts      : new FormControl<number | null>(null, []),
    totalCost                   : new FormControl<number | null>(null),
    totalPopulation             : new FormControl<number | null>(null),
    onNeviRoute                 : new FormControl<number | null>(null),
    incomeAndReturnPerYear      : new FormControl<number | null>(null),
    predictedEvRegistrations    : new FormControl<number | null>(null),
  });

  public $parentForm = new CcFormGroup({
    steps: new CcFormArray([this.$form]),
  });


  public ngOnInit(): void {

    this.siteResolver = this.route.snapshot.data['data'];

    this.$parentForm.get('steps')?.valueChanges.pipe(
        map((collection: any[]) => collection.reduce(
            (acc, formCollection) => ({ ...acc, ...formCollection }), {}
          )
        ),
        tap((formArrayMap) => {
          this.$formArrayMap = formArrayMap;
        }),
        distinctUntilChanged(),
        switchMap(() => this.SiteService.sub$)
      )
      .subscribe(async (key) => {

        if (key == 'init@GEOCODE') {
          this.SiteService.$serviceGetGeocodeData(this.$formArrayMap);
        }

        if (key == 'init@UTILITY') {

          this.SiteService.$serviceGetUtilityCollection(this.$formArrayMap);
          this.processOnSiteLocationCalculated();
        }

        if (key == 'select_utility') {
          this.SiteService.$serviceGetUtilityPlan(this.$formArrayMap);
        }
      });
  }

  /**
   * @author Mihail Petrov
   * @returns
   */
  public getFormArray() {
    return this.$parentForm.get('steps') as FormArray;
  }

  /**
   *
   */
  public async ngAfterViewInit() {

    if (!this.siteResolver.siteModel) return;

    this.fu.trackAndUpdateControls(this.getFormArray(), this.siteResolver.siteModel.data);
    this.cdref.detectChanges();

    this.SiteService.sendFormData('process@HardwareConfig', this.siteResolver?.siteModel?.data?.hardwareConfigCollection);

    await this.SiteService.$serviceGetGeocodeData(this.$formArrayMap);
    await this.SiteService.$serviceGetUtilityCollection(this.$formArrayMap);
    await this.SiteService.$serviceGetUtilityPlan(this.$formArrayMap);

    this.SiteService.sendFormData('process@DATE'          , this.$formArrayMap.anticipatedStartDate);
    this.SiteService.sendFormData('selectUtilityGroup'    , this.$formArrayMap);
    this.SiteService.sendFormData('process@DRIVER_PRICE'  , this.$formArrayMap.driverPriceMultiplier);

    this.$$ui.state.isUtilityDetailsPreviewVisible = true;
    this.$$ui.state.isPricingDetailsPreviewVisible = true;

    if (this.siteResolver.siteModel.data.roiIsScored) {
      this.$$ui.state.isRoiPreviewVisible = true;
    }
  }

  /**
   * @author Mihail Petrov
   * @param stepId
   */
  public processStep(stepId: number) {

    if (stepId < this.wizzardIndex) {
      this.wizzardIndex = stepId;
      return;
    }

    (this.getFormArray().at(this.wizzardIndex) as CcFormGroup)?.markFormGroupDirty().isFormValid(() => {
        this.wizzardIndex = stepId;
      });
  }

  /**
   * @author Mihail Petrov
   */
  public processNext() {

    (this.getFormArray().at(this.wizzardIndex) as CcFormGroup)?.markFormGroupDirty().isFormValid(() => {
        if (this.wizzardIndex == 2) this.processScore();
        if (this.wizzardIndex == 1) this.wizzardIndex++;
      });
  }

  /**
   * @author Mihail Petrov
   */
  public processPrev() {
    this.wizzardIndex--;
  }

  /**
   * @author Mihail Petrov
   */
  public async processScore() {

    const projectSummary: any = await lastValueFrom(this.SiteService.$scoreSite(this.$formArrayMap));

    const roiPeriod = projectSummary.breakevenYear == null ? 0 : projectSummary.breakevenYear;

    this.$form.get('roiPeriod'                  )?.setValue(roiPeriod);
    this.$form.get('roiAvgWeeklyVisits'         )?.setValue(projectSummary.avgWeeklyVisits);
    this.$form.get('roiNpv'                     )?.setValue(projectSummary.npv);
    this.$form.get('roiMonthlyEnergyConsumption')?.setValue(projectSummary.monthlyEnergyConsumptionKwh);
    this.$form.get('roiWeeklyEnergyConsumption' )?.setValue(projectSummary.weeklyEnergyConsumptionKwh);
    this.$form.get('suggestedNumberOfPorts'     )?.setValue(projectSummary.suggestedNumberOfPorts);
    this.$form.get('roiIsScored'                )?.setValue(true);
    this.$form.get('totalPopulation'            )?.setValue(projectSummary.totalPopulation);
    this.$form.get('onNeviRoute'                )?.setValue(projectSummary.isOnNeviRoute);
    this.$form.get('incomeAndReturnPerYear'     )?.setValue(projectSummary.incomeAndReturnPerYear);
    this.$form.get('predictedEvRegistrations'   )?.setValue(projectSummary.predictedEvRegistrations);

    this.$$ui.state.isRoiPreviewVisible = true;

    await this.processSave();

    this.$$ui.state.isReportAvailable = true;
  }

  /**
   * @author Mihail Petrov
   */
  public processSaveAction() {

    (this.getFormArray().at(2) as CcFormGroup)?.markFormGroupDirty().isFormValid(async () => {
        await this.processSave();
    });
  }

  /**
   * @author Mihail Petrov
   */
  public async processSave() {

    if (this.siteResolver.siteModel) {

      return await lastValueFrom(this.$siteApiService.updateSite({
          id        : this.siteResolver.siteModel.id as string,
          projectId : this.siteResolver.projectId,
          name      : this.$formArrayMap.name,
          data      : this.$formArrayMap,
        })
      );
    }

    const response = await lastValueFrom(this.$siteApiService.createNewSite({
        projectId   : this.siteResolver.projectId,
        name        : this.$formArrayMap.name,
        data        : this.$formArrayMap,
      })
    );

    this.siteResolver.siteModel = response.data;

    this.globalRedirect.inContextOfApplication(false).viewSite(this.siteResolver.siteModel.id!);
    return this.siteResolver.siteModel;
  }

  /**
   * @author Mihail Petrov
   */
  public processOnSiteLocationCalculated() {
    this.$$ui.state.isSiteLocationPreviewVisible = true;
  }

  /**
   * @author Mihail Petrov
   */
  public processOnUtilityDetailsCalculated() {
    this.$$ui.state.isUtilityDetailsPreviewVisible = true;
  }

  /**
   * @author Mihail Petrov
   */
  public processOnPricingDetailsCalculated() {
    this.$$ui.state.isPricingDetailsPreviewVisible = true;
  }

  /**
   * @author Mihail Petrov
   */
  public async downloadReport() {

    const id                                      = this.siteResolver?.siteModel?.id as string;
    const reportCollection: any                   = (await lastValueFrom(this.$reportApi.getSiteReport(id)))?.data;

    this.$$ui.state.isSidebarManageReportsVisible = true;
    this.$$ui.data.sidebarTransactionObject       = reportCollection;
  }

  /**
   * @author Mihail Petrov
   * @param $event
   */
  public processDownloadReport($event: ReportModel) {
    window.open($event.url, '_blank');
  }

  /**
   *
   */
  public goBack() {
    this.globalRedirect.inContextOfApplication().viewProject(this.siteResolver.projectId);
  }
}
