import { HttpClient, HttpParams } from "@angular/common/http";
import { inject, Injectable     } from "@angular/core";
import { environment            } from "environments/environment";
import { Meta } from "nv@api/customer.api.service";
import { Observable             } from "rxjs";

export type ApiResponseModel = {
  status  : string;
  code    : number;
  message : string;
  data    : any[] | any;
  errors  : string[]
}

export class HttpApiServiceRequest {

  // private url: string
  private body: any;
  private queryParameters = new HttpParams();
  private filterQuery: any[]     = [];

  public constructor(
    private $http: HttpClient,
    private resourceUrl: string) { }

  /**
   * @author Mihail Petrov
   * @returns
   */
  public withBody(object: any): HttpApiServiceRequest {

    this.body = object;
    return this;
  }


  /**
   * @author Mihail Petrov
   * @returns
   */
  public withQuery(key: string, value: string | number): HttpApiServiceRequest {

    if(value != undefined){
      this.queryParameters = this.queryParameters.append(key, value);
    }

    return this;
  }


    /**
   * @author Mihail Petrov
   * @returns
   */
    public withFilterQuery(key: string, value: string | number): HttpApiServiceRequest {

      if(value != undefined) {

        this.filterQuery.push(`${key}:${value}`);
        this.queryParameters = this.queryParameters.append("filter", this.filterQuery.join(','));
      }

      return this;
    }



  /**
   * @author Mihail Petrov
   * @returns
   */
  public fetch(): Observable<ApiResponseModel> {

    return this.$http.get<ApiResponseModel> ( this.resourceUrl, {
      params: this.queryParameters
    });
  }

  /**
   * @author Mihail Petrov
   * @returns
   */
  public create(): Observable<ApiResponseModel>  {

    return this.$http.post<ApiResponseModel> ( this.resourceUrl, this.body, {
      params: this.queryParameters
    });
  }

  /**
   * @author Mihail Petrov
   * @description Somethimes CREATE does not make sense - so we have a clasic alias
   * @returns
   */
  public post(): Observable<ApiResponseModel>  {
    return this.create()
  }

  /**
   * @author Mihail Petrov
   * @returns
   */
  public update(): Observable<ApiResponseModel>  {

    return this.$http.put<ApiResponseModel> ( this.resourceUrl, this.body, {
      params: this.queryParameters
    });
  }

  /**
   * @author Mihail Petrov
   * @returns
   */
  public remove(): Observable<ApiResponseModel>  {

    return this.$http.delete<ApiResponseModel>  ( this.resourceUrl, {
      params: this.queryParameters
    });
  }
}

@Injectable()
export class HttpApiService {

  protected $http = inject(HttpClient);

  public buildUrl(
    serviceId: keyof typeof environment.serviceUrls, url: (string|number)[]
  ): string {

    const serviceUrl: string = environment.serviceUrls[serviceId];
    return `${serviceUrl}/${url.join('/')}`
  }

  /**
   * @author Mihail Petrov
   * @param serviceId
   * @param resource
   * @returns
   */
  public forEndpoint(
    serviceId: keyof typeof environment.serviceUrls, ...resource: (string|number)[]
  ): HttpApiServiceRequest {
    return new HttpApiServiceRequest(this.$http, this.buildUrl(serviceId, resource));
  }
}
