import { Component      } from "@angular/core";

import { SidebarModule  } from "primeng/sidebar";
import { CcBaseSidebar  } from "./cc-sidebar.base";

@Component({
  selector    : 'cc-sidebar',
  templateUrl : 'cc-sidebar.component.html',
  styleUrl    : './cc-sidebar.component.scss',
  standalone  : true,
  imports     : [ SidebarModule ]
})
export class CcSidebar extends CcBaseSidebar {}
