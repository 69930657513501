import { inject, Injectable } from "@angular/core";
import { CcTableModel       } from "nv@components/cc-table/cc-table.model";
import { TranslateService   } from "nv@services/translate.service";

@Injectable()
export class ProjectState {

  private ts = inject(TranslateService);

  public state = {
    isSidebarManageSitesVisible     : false,
    isSidebarManageReportsVisible   : false,
    isSidebarManageContactsVisible  : false,
    isSidebarManageProjectStatus    : false,
    isSidebarCreateContactVisible   : false
  }

  public data = {
    sidebarTransactionObject  : null,
    sidebarTransactionAction  : ''
  };

  public siteMapping: CcTableModel[]        = [

    { column: this.ts.translate('@@PROJECT.TABLE.SITE_NAME')        , value : 'data.name', isNavigatable: true                      },
    { column: this.ts.translate('@@PROJECT.TABLE.ADDRESS')          , fn    : (el: any) => `${el.data.address}, ${el.data.city}`    },
    { column: this.ts.translate('@@PROJECT.TABLE.ON_NEVI')          , fn    : (el: any) => (el.data.onNeviRoute) ? 'YES' : 'NO'     },
    { column: this.ts.translate('@@PROJECT.TABLE.PORTS')            , value : 'data.numberOfPorts'                                  },
    { column: this.ts.translate('@@PROJECT.TABLE.CAPITAL_EXPENSE')  , value : 'data.capitalExpenses'    , type: 'money'             },

    { column: this.ts.translate('@@PROJECT.TABLE.OPERATING_EXPENSE'), value : 'data.operatingExpenses'  , type: 'money'             },
    { column: this.ts.translate('@@PROJECT.TABLE.EXPECTED_ROI')     , fn    : (el: any) => `${el.data.roiPeriod} years`             },
    { column: this.ts.translate('@@PROJECT.TABLE.DEPLOYMENT')       , value : 'data.anticipatedStartDate' , type: 'date'            },
  ];
}
