@if(inputIsVisible)
{
  <div class="card-box">

    <div>
      <i class="pi pi-bolt fs32 m5"></i>
    </div>

    <div class="ml16">

      <div class="just-flex" style="align-items: center;">

        <div style="font-weight: bold;" class="mr16">Electricity cost for </div>

        <div class="fsb18 pill">
          {{ inputData?.utilityPlanType?.toUpperCase() }}
        </div>
        <div style="font-weight: bold;">tier</div>
      </div>

      <div class="mt16">
        @if(inputData?.utilityPlanType == 'fixed')
        {

          <div class="just-flex mt8" style="align-items: center;">
            <div style="width: 126px;">fixed montly</div>
            <i style="font-size:12px; margin-top: 8px; margin-left: 8px; margin-right: 8px"
            class="pi pi-angle-double-right"></i>
            <div class="pill pill--green" style="font-size: 18px;">{{inputData?.fixedMonthlyCost | currency:'USD'}}</div>
          </div>

          <div class="just-flex mt16" style="align-items: center;">
            <div style="width: 126;">fixed price</div>
            <i style="font-size:12px; margin-top: 8px; margin-left: 8px; margin-right: 8px"
            class="pi pi-angle-double-right"></i>
            <div class="pill" style="font-size: 18px;">{{inputData?.electricityCost | currency:'USD'}}</div>
          </div>
        }

        @if(inputData?.utilityPlanType == 'tou')
        {


          <div class="just-flex mt8" style="align-items: center;">
            <div style="width: 126px;">fixed montly</div>
            <i style="font-size:12px; margin-top: 8px; margin-left: 8px; margin-right: 8px"
            class="pi pi-angle-double-right"></i>
            <div class="pill pill--green" style="font-size: 18px;">{{inputData?.fixedMonthlyCost | currency:'USD'}}</div>
          </div>

          <div class="just-flex mt16" style="align-items: center;">
            <div style="width: 126px;">on peak</div>
            <i style="font-size:12px; margin-top: 8px; margin-left: 8px; margin-right: 8px"
            class="pi pi-angle-double-right"></i>
            <div class="pill pill--green" style="font-size: 18px;">{{inputData?.electricityCostPeak | currency:'USD'}}</div>
          </div>

          <div class="just-flex mt8" style="align-items: center;">
            <div style="width: 126px;">off peak</div>
            <i style="font-size:12px; margin-top: 8px; margin-left: 8px; margin-right: 8px"
            class="pi pi-angle-double-right"></i>
            <div class="pill" style="font-size: 18px;">{{inputData?.electricityCostOffPeak | currency:'USD'}}</div>
          </div>

          <div class="just-flex mt8" style="align-items: center;">
            <div style="width: 126px;">super off peak</div>
            <i style="font-size:12px; margin-top: 8px; margin-left: 8px; margin-right: 8px"
            class="pi pi-angle-double-right"></i>
            <div class="pill pill--red" style="font-size: 18px;">{{inputData?.electricityCostSuperOffPeak | currency:'USD'}}</div>
          </div>
        }

      </div>
    </div>
  </div>
}
@else
{
  <div class="cc-placeholder">
    <div>
      <i class="pi pi-bolt fs32 m5"></i>
    </div>
    <div class="ml16 fs24"> {{'@@SITE.TEMPLATE.ELECTRICITY_PRICE.NAME' | translate}} </div>
  </div>
}
