<cc-sidebar
  [(inputIsVisible)]    = "inputIsVisible"
  (onHide)              = "processHideSidebar()"
  [inputTitle]          = "sidebarTitle"
  actionEnter (onEnter) ="processForm()">

  @if(isInCreateMode())
  {
    <template-create
      [inputData]             = "inputData"
      [$form]                 = "$form"
      [projectContactList]    = "projectContactList"
      (onProcess)             = "processForm()"
      (onAddContact)          = "addContact($event)"
      (onMakeContactPrimary)  = "makeItPrimary($event)"
      (onRemoveContact)       = "removeContactFromProject($event)"
      (onAddContactToProject) = "addContactToProject($event)">
    </template-create>
  }

  @if(isInEditMode())
  {
    <template-edit
      [inputData]             = "inputData"
      [$form]                 = "$form"
      [projectContactList]    = "projectContactList"
      (onProcess)             = "processForm()"
      (onAddContact)          = "addContact($event)"
      (onMakeContactPrimary)  = "makeItPrimary($event)"
      (onRemoveContact)       = "removeContactFromProject($event)"
      (onAddContactToProject) = "addContactToProject($event)">
    </template-edit>
  }

  @if(isInDeleteMode())
  {
    <template-delete
      [inputData] = "inputData"
      (onCancel)  = "processHideSidebar()"
      (onDelete)  = "removeEntity()">
    </template-delete>
  }


  @if(isInImportMode())
  {
    <template-import
      [$form]     = "$form"
      (onUpload)  = "processFile($event)">
    </template-import>
  }

</cc-sidebar>
