@if(inputIsVisible)
{
  <div class="card-box">

    <div>
      <i class="pi pi-microchip fs32 m5"></i>
    </div>

    <div class="ml16 mt8">
      {{'@@SITE.TEMPLATE.NUMBER_OF_PORTS.NUMBER_OF_PORTS' | translate}}
      <span style="font-size: 20px; font-weight: bold; margin-left: 8px;">
        {{inputData?.numberOfPorts}}
      </span>
    </div>
  </div>
}
@else
{
  <div class="cc-placeholder">
    <i class="pi pi-microchip fs32 m5"></i>
    <div class="ml16 fs24"> {{'@@SITE.TEMPLATE.NUMBER_OF_PORTS.NAME' | translate}} </div>
  </div>
}
