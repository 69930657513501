@if(inputStatus == 'OPEN')
{
  <div style="width: 100px; text-align: center; font-size: 12px; text-transform: capitalize; background: #D3F26A; color: #226092; padding: 4px 8px; border-radius: 8px;">
    Open
  </div>
}

@if(inputStatus == 'CLOSE_WON')
{
  <div style="width: 120px; text-align: center; font-size: 12px; text-transform: capitalize; background: #f0db4f; color: #226092; padding: 4px 8px; border-radius: 8px;">
    Closed won
  </div>
}

@if(inputStatus == 'CLOSE_LOST')
{
  <div style="width: 120px; text-align: center; font-size: 12px; text-transform: capitalize; background: rgba(217, 28, 92, 0.8); color: #fff; padding: 4px 8px; border-radius: 8px;">
    Closed lost
  </div>
}

@if(inputStatus == 'CLOSE_FUNDING')
{
  <div style="width: 120px; text-align: center; font-size: 12px; text-transform: capitalize; background: #dedede; color: #226092; padding: 4px 8px; border-radius: 8px;">
    Closed funding
  </div>
}
