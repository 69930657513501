import { CommonModule         } from "@angular/common";
import { Component            } from "@angular/core";
import { FormControl, ReactiveFormsModule, Validators  } from "@angular/forms";
import { SidebarModule        } from 'primeng/sidebar';
import { DropdownModule       } from "primeng/dropdown";

import { CcBaseSidebar        } from "nv@components/cc-sidebar/cc-sidebar.base";
import { CcSidebar            } from "nv@components/cc-sidebar/cc-sidebar.component";
import { TranslatePipe        } from "nv@services/translate.service";
import { TemplateImport       } from "./@templates/template-import/template-import.component";
import { CcFormGroup } from "app/@shared/forms/form-group/cc-form-group.extention";

@Component({
  selector    : 'sidebar-manage-sites',
  templateUrl : './manage-sites.component.html',
  styleUrl    : './manage-sites.component.scss',
  imports     : [
    ReactiveFormsModule ,
    CommonModule        ,
    SidebarModule       ,
    SidebarModule       ,
    CcSidebar           ,
    DropdownModule      ,
    TranslatePipe       ,
    TemplateImport
  ]
})
export class SidebarManageSites extends CcBaseSidebar {

  public sidebarTitle  = 'Remove site';

  public $form = new CcFormGroup({
    name  : new FormControl(null, [Validators.required]),
  });

  public ngOnChanges() {

    if(this.isInDeleteMode()) {
      this.sidebarTitle = 'Remove site';
    }

    if(this.isInImportMode()) {
      this.sidebarTitle = 'Import site';
    }
  }

  /**
   * @author Mihail Petrov
   */
  public removeEntity() {
    this.returnToParent(this.inputData);
  }

  /**
   * @author Mihail Petrov
   * @param event
   */
  public processFile(event: any) {

    const file: File  = event.files[0];
    const formData    = new FormData();
    formData.append('file', file, file.name);

    this.returnToParent(formData);
  }
}
