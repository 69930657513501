import {  inject                  } from "@angular/core";
import {  ActivatedRouteSnapshot  ,
          ResolveFn               ,
          RouterStateSnapshot     } from "@angular/router";
import {  GlobalRedirectService   } from "app/@routes/redirect.service";
import {  CustomerApi             } from "nv@api/customer.api.service";
import {  lastValueFrom           } from "rxjs";

/**
 * @author Mihail Petrov
 * @param route
 * @param state
 * @returns Observable
 */
export const CustomerResolver: ResolveFn<any> = async (
  route: ActivatedRouteSnapshot, state: RouterStateSnapshot
) => {

    const globalRedirect            = inject(GlobalRedirectService)
    const $customerApi              = inject(CustomerApi)
    const routerId: string | null   = route.paramMap.get('id');

    if(routerId == null) {
      return globalRedirect.inContextOfApplication().redirectTo404();
    }

    try {
      return (await lastValueFrom($customerApi.getCustomer(routerId)))?.data
    }
    catch(error) {
      return globalRedirect.inContextOfApplication().redirectTo404();
    }
};
