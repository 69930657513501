<div class="panel">
  <span style="font-weight: bold;">
  {{inputData.firstName}} {{inputData.lastName}}</span>
  {{'@@MANAGE_CONTACTS.PAGE.REMOVE_MESSAGE' | translate }}
</div>

<div style="display: flex; margin-top: 16px; justify-content: flex-end;">
  <div (click)="onCancel.emit()" class="btn mr16">
    <i class="pi pi-times"></i>
    {{'@@MANAGE_CONTACTS.ACTION.CANCEL' | translate }}
  </div>
  <div (click)="onDelete.emit()" class="btn btn-danger">
    <i class="pi pi-trash"></i>
    {{'@@MANAGE_CONTACTS.ACTION.REMOVE' | translate }}
  </div>
</div>
