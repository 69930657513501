<div class="page-title mb32">
  <h1 style="cursor: pointer;" (click)="goHome()">
    <i class="pi pi-angle-left mr16"></i>
    {{customerDetail?.name}}
  </h1>
</div>

<div class="just-flex" style="cursor: pointer;">
  <div
    class="tab"
    [ngClass]="{'tab--active': $$ui.state.activeTab == 'projects'}"
    (click)="onTabChange('projects')">{{'@@CUSTOMER.PAGE.TAB_PROJECTS' | translate}}</div>

  <div
    class="tab"
    [ngClass]="{'tab--active': $$ui.state.activeTab == 'contacts'}"
    (click)="onTabChange('contacts')">{{'@@CUSTOMER.PAGE.TAB_CONTACTS' | translate}}</div>
</div>

<div class="mt32">

  @if(this.$$ui.isActiveTabProject())
  {
    <template-project-tab
      [inputDataSource] ="projectCollection"
      [inputHasData]    = "$$ui.state.hasProjects"

      (onCreate)        = "processOnCreateNewProject()"
      (onImport)        = "processOnImportProject()"
      (onEdit)          = "processOnProjectEdit($event)"
      (onDelete)        = "processOnProjectDelete($event)"
      (onNavigate)      = "processOnNavigate($event)"
      (onPageChange)    = "processOnChangeProjectPage($event)"
      (onSearch)        = "processOnSearchProject($event)">
    </template-project-tab>
  }

  @if(this.$$ui.isActiveTabContact())
  {
    <template-contact-tab
      [inputDataSource] = "contactCollection"
      [inputHasData]    = "$$ui.state.hasContacts"

      (onCreate)        = "processOnCreateNewContact()"
      (onImport)        = "processOnImportContact()"
      (onEdit)          = "processOnContactEdit($event)"
      (onDelete)        = "processOnContactDelete($event)"
      (onNavigate)      = "processOnNavigate($event)"
      (onPageChange)    = "processOnChangeContactPage($event)"
      (onSearch)        = "processOnSearchContact($event)">
    </template-contact-tab>
  }
</div>

<!--  -->
<sidebar-manage-projects
  [(inputIsVisible)]    = "this.$$ui.state.isSidebarManageProjectsVisible"
  [inputData]           = "{transactionObject: this.$$ui.data.sidebarTransactionObject, contactList: contactCollection}"
  [inputSidebarAction]  = "this.$$ui.data.sidebarTransactionAction"
  (onReturnObject)      = "processProject($event)">
</sidebar-manage-projects>

<!--  -->
<sidebar-manage-contacts
  [(inputIsVisible)]    = "this.$$ui.state.isSidebarManageContactsVisible"
  [inputData]           = "this.$$ui.data.sidebarTransactionObject"
  [inputSidebarAction]  = "this.$$ui.data.sidebarTransactionAction"
  (onReturnObject)      = "processContact($event)">
</sidebar-manage-contacts>
