import {  inject            ,
          Injectable        } from "@angular/core";
import {  CcTableModel      } from "nv@components/cc-table/cc-table.model";
import {  TranslateService  } from "nv@services/translate.service";

@Injectable()
export class CustomerState {

  private ts = inject(TranslateService);

  public state = {

    activeTab                       : 'projects',
    isSidebarManageProjectsVisible  : false,
    isSidebarManageContactsVisible  : false,

    hasProjects : false,
    hasContacts : false
  }

  public data = {

    sidebarTransactionObject  : null,
    sidebarTransactionAction  : ''
  };


  public isActiveTabProject() {
    return this.state.activeTab == 'projects';
  }

  public isActiveTabContact() {
    return this.state.activeTab == 'contacts';
  }

  /**
   *
   */
  public customerMapping: CcTableModel[]    = [
    { column: 'Customer Name'       , value: 'name'                 , isNavigatable: true },
    { column: 'Number of Projects'  , value: 'numberOfOpenProjects' , isNavigatable: false }
  ];

  /**
   *
   */
  public projectsMapping: CcTableModel[]    = [

    {column: this.ts.translate('@@CUSTOMER.TABLE.PROJECT_NAME'    ), value : 'name', isNavigatable: true          },
    {column: this.ts.translate('@@CUSTOMER.TABLE.CONTACTS'        ), value : 'primaryContactName'                 },
    {column: this.ts.translate('@@CUSTOMER.TABLE.STATUS'          ), value : 'status', directive: 'project-label' },
    {column: this.ts.translate('@@CUSTOMER.TABLE.SUGGESTED_PORTS' ), value : 'summary.numberOfPorts'              },
    {column: this.ts.translate('@@CUSTOMER.TABLE.N_SITES'	        ), value : 'summary.numberOfSites'                },
    {column: this.ts.translate('@@CUSTOMER.TABLE.CAPITAL_EXPENSE' ), value : 'summary.totalOperatingExpenses', type: 'money'   },
    {column: this.ts.translate('@@CUSTOMER.TABLE.OVERALL_COSTS'   ), value : 'summary.totalCapitalExpenses', type: 'money'   },
    {column: this.ts.translate('@@CUSTOMER.TABLE.EXPECTED_ROI'    ), fn : (element: any) => {
      return  (element?.summary?.expectedRoi) ? `${element?.summary?.expectedRoi} years` : '-';
    }},
    {column: this.ts.translate('@@CUSTOMER.TABLE.DEPLOYMENT'      ), value : 'summary.deploymentDay', type: 'date'  },
  ];

  /**
   *
   */
  public contactMapping: CcTableModel[] = [
    { column: this.ts.translate('@@CUSTOMER.TABLE.FULL_NAME')       , fn    : (element: any) => {
      return `${element.firstName} ${element.lastName}`;
    }},
    { column: this.ts.translate('@@CUSTOMER.TABLE.ROLE'    ), value : 'role'       , icon: 'pi pi-id-card'     },
    { column: this.ts.translate('@@CUSTOMER.TABLE.PHONE'   ),

      fn    : (element: any) => {
        return `(${element.phoneCc}) ${element.phone}`;
      }


      , icon: 'pi pi-phone'       },
    { column: this.ts.translate('@@CUSTOMER.TABLE.EMAIL'   ), value : 'email'      , icon: 'pi pi-envelope'    },
  ];
}
