import {  inject                  } from "@angular/core";
import {  ActivatedRouteSnapshot  ,
          ResolveFn               ,
          RouterStateSnapshot     } from "@angular/router";
import {  lastValueFrom           } from "rxjs";
import {  GlobalRedirectService   } from "app/@routes/redirect.service";
import {  ProjectApi              } from "nv@api/project.api.service";

/**
* @author Mihail Petrov
 * @param route
 * @param state
 * @returns Observable
 */
export const ProjectResolver: ResolveFn<any> = async (
  route: ActivatedRouteSnapshot, state: RouterStateSnapshot
) => {

    const globalRedirect          = inject(GlobalRedirectService)
    const $projectApi             = inject(ProjectApi)
    const routerId: string | null = route.paramMap.get('id');

    if(routerId == null) {
      return globalRedirect.inContextOfApplication().redirectTo404();
    }

    try {
      return (await lastValueFrom($projectApi.getProject(routerId)))?.data
    }
    catch(error) {
      return globalRedirect.inContextOfApplication().redirectTo404();
    }
};
