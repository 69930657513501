import { Injectable } from "@angular/core";

@Injectable({providedIn: 'root'})
export class UtilityCollectionService {

  /**
   * @author Mihail Petrov
   * @returns
   */
  public getHardwareConfigurationSetup() {

    return this.getHardwareConfiguration().filter((item, index, self) =>
      index === self.findIndex(otherItem => otherItem.name === item.name)
    );
  }

  /**
   * @author Mihail Petrov
   * @param id
   */
  public get(id: string, options: { hasNACScable: boolean, isBabaApplicable: string, isNaviCamplient: boolean }) {

    for(const element of this.getHardwareConfiguration()) {

      if(element.name               != id                       ) continue;
      if(element.hasNACScable       != options.hasNACScable     ) continue;
      if(element.isNaviCamplient    != options.isNaviCamplient  ) continue;

      if( (element.isBabaApplicable != 'NA') &&
          (element.isBabaApplicable != options.isBabaApplicable )) {
        continue;
      }

      return element;
    }

    return null;
  }

  /**
   * @author Mihail Petrov
   * @returns
   */
  public getHardwareConfiguration() {

    return [
      {
        name              : 'All in One 200 kW Charger'  ,
        numberOfPorts     : 1,
        hardwareCost      : 75000,
        installationCost  : 75000,
        capacity          : 200,
        hasNACScable      : false,
        isBabaApplicable  : 'NA',
        isNaviCamplient   : false
      },
      {
        name              : 'All in One 200 kW Charger'  ,
        numberOfPorts     : 1,
        hardwareCost      : 76500,
        installationCost  : 76500,
        capacity          : 200,
        hasNACScable      : true,
        isBabaApplicable  : 'NA',
        isNaviCamplient   : false
      },
      {
        name              : 'Distributed Solution 600 kW - 1 Charger'  ,
        numberOfPorts     : 1,
        hardwareCost      : 225000,
        installationCost  : 225000,
        capacity          : 600,
        hasNACScable      : false,
        isBabaApplicable  : 'NA',
        isNaviCamplient   : false
      },
      {
        name              : 'Distributed Solution 600 kW - 1 Charger'  ,
        numberOfPorts     : 1,
        hardwareCost      : 225000,
        installationCost  : 225000,
        capacity          : 600,
        hasNACScable      : true,
        isBabaApplicable  : 'NA',
        isNaviCamplient   : false
      },


      {
        name              : 'Distributed Solution 600 kW - 2 Chargers'  ,
        numberOfPorts     : 2,
        hardwareCost      : 281250,
        installationCost  : 281250,
        capacity          : 600,
        hasNACScable      : false,
        isBabaApplicable  : 'N',
        isNaviCamplient   : false
      },
      {
        name              : 'Distributed Solution 600 kW - 2 Chargers'  ,
        numberOfPorts     : 2,
        hardwareCost      : 284250,
        installationCost  : 284250,
        capacity          : 600,
        hasNACScable      : true,
        isBabaApplicable  : 'N',
        isNaviCamplient   : false
      },
      {
        name              : 'Distributed Solution 600 kW - 2 Chargers'  ,
        numberOfPorts     : 2,
        hardwareCost      : 325000,
        installationCost  : 325000,
        capacity          : 600,
        hasNACScable      : false,
        isBabaApplicable  : 'Y',
        isNaviCamplient   : false
      },
      {
        name              : 'Distributed Solution 600 kW - 2 Chargers'  ,
        numberOfPorts     : 2,
        hardwareCost      : 328000,
        installationCost  : 328000,
        capacity          : 600,
        hasNACScable      : true,
        isBabaApplicable  : 'Y',
        isNaviCamplient   : false
      },
      {
        name              : 'Distributed Solution 600 kW - 4 Chargers'  ,
        numberOfPorts     : 4,
        hardwareCost      : 375000,
        installationCost  : 375000,
        capacity          : 600,
        hasNACScable      : false,
        isBabaApplicable  : 'NA',
        isNaviCamplient   : false
      },
      {
        name              : 'Distributed Solution 600 kW - 4 Chargers'  ,
        numberOfPorts     : 4,
        hardwareCost      : 387000,
        installationCost  : 387000,
        capacity          : 600,
        hasNACScable      : true,
        isBabaApplicable  : 'NA',
        isNaviCamplient   : false
      },
      {
        name              : 'Wallbox 30 kW Charger'  ,
        numberOfPorts     : 1,
        hardwareCost      : 12000,
        installationCost  : 12000,
        capacity          : 30,
        hasNACScable      : false,
        isBabaApplicable  : 'NA',
        isNaviCamplient   : false
      },
      {
        name              : 'Wallbox 30 kW Charger'  ,
        numberOfPorts     : 1,
        hardwareCost      : 12000,
        installationCost  : 12000,
        capacity          : 30,
        hasNACScable      : true,
        isBabaApplicable  : 'NA',
        isNaviCamplient   : false
      }
    ];
  }
}
