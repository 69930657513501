@if (inputIsVisible)
{
    <div class="card-box" style="padding: 16px;">

        <div>
            <i class="pi pi-dollar fs32 m5"></i>
        </div>

        <div class="ml16 mt8">
            <!-- Title Row -->
            <div class="just-flex" style="align-items: center;">
                <span class="ml8" style="font-size: 18px; font-weight: 600;">
                    Capital Expense Breakdown
                </span>
            </div>

            <div class="ml16 mt8">
                <!-- Federal/State Incentives -->
                @if (inputData?.incentives > 0)
                {
                    <div class="just-flex mt8" style="align-items: center;">
                        <div style="width: 140px;">Federal/State Incentives</div>
                        <i style="font-size:12px;" class="pi pi-angle-double-right mt8 ml8 mr8"></i>
                        <div style="font-size:18px; color: #008000;">
                            {{ inputData?.incentives | currency:'USD':'symbol':'1.0-0' }}
                        </div>
                    </div>
                }

                <!-- 30C Incentives -->
                @if (inputData?.hasIncentives30c)
                {
                    <div class="just-flex mt8" style="align-items: center;">
                        <div style="width: 140px;">30C Incentives</div>
                        <i style="font-size:12px;" class="pi pi-angle-double-right mt8 ml8 mr8"></i>
                        <div style="font-size:18px; color: #008000;">
                        {{ 100000 | currency:'USD':'symbol':'1.0-0' }}
                        </div>
                    </div>
                }

                <!-- Hardware Cost -->
                @if (inputData?.hardwareCost > 0)
                {
                    <div class="just-flex mt8" style="align-items: center;">
                        <div style="width: 140px;">Hardware</div>
                        <i style="font-size:12px;" class="pi pi-angle-double-right mt8 ml8 mr8"></i>
                        <div style="font-size:18px; color: #c9302c;">
                            {{ inputData?.hardwareCost | currency:'USD':'symbol':'1.0-0' }}
                        </div>
                    </div>
                }

                <!-- Installation Cost -->
                @if (inputData?.installationCost > 0)
                {
                    <div class="just-flex mt8" style="align-items: center;">
                        <div style="width: 140px;">Installation</div>
                        <i style="font-size:12px;" class="pi pi-angle-double-right mt8 ml8 mr8"></i>
                        <div style="font-size:18px; color: #c9302c;">
                            {{ inputData?.installationCost | currency:'USD':'symbol':'1.0-0' }}
                        </div>
                    </div>
                }

                <!-- Grand Total -->
                <div class="just-flex mt16" style="align-items: center;">
                    <div style="font-size:24px; font-weight:bold;">
                    {{
                        (
                        ((inputData?.hardwareCost      ?? 0) + (inputData?.installationCost ?? 0)) - ((inputData?.hasIncentives30c ? 100000 : 0) + (inputData?.incentives ?? 0))
                        )
                        | currency:'USD':'symbol':'1.0-0'
                    }}
                    </div>
                </div>

            </div><!-- /margin-top -->
        </div>
    </div><!-- /card-box -->
}
@else
{
    <!-- If not visible, show placeholder -->
    <div class="cc-placeholder">
        <i class="pi pi-dollar fs32 m5"></i>
        <div class="ml16 fs24">
        Capital Expense Breakdown
        </div>
    </div>
}
