import {  CommonModule  } from '@angular/common';
import {  Component     ,
          Input         } from '@angular/core';
import {  TranslatePipe } from 'nv@services/translate.service';

@Component({
  standalone  : true,
  selector    : 'template-form-header',
  templateUrl : './template-form-header.component.html',
  imports     : [ CommonModule, TranslatePipe ]
})
export class TemplateFormHeader {

  @Input() public inputIcon!: string;
  @Input() public inputTitle!: string;
}
