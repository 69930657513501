import { Component              ,
         ViewChild              ,
         ElementRef             ,
         Input                  ,
         OnChanges              ,
         TemplateRef            ,
         inject                 ,
         ViewContainerRef       } from '@angular/core';
import { GlobalRedirectService  } from 'app/@routes/redirect.service';
import { AuthenticationType     } from 'azure-maps-control';
import { SiteModel              } from 'nv@models/site.model';
import { formatCurrency         } from '@angular/common';
import * as atlas from "azure-maps-control"

function calculateCenter(markers: any) {

  const total = markers.length;
  let latSum = 0;
  let lngSum = 0;

  markers.forEach((marker: any) => {
      latSum += marker.latitude;
      lngSum += marker.longitude;
  });

  return {
      latitude: latSum / total,
      longitude: lngSum / total
  };
}

function calculateBounds(markers: any) {

  const bounds = {
      southwest: { lat: Infinity, lng: Infinity },
      northeast: { lat: -Infinity, lng: -Infinity }
  };

  markers.forEach((marker: any) => {

      bounds.southwest.lat = Math.min(bounds.southwest.lat, marker.latitude);
      bounds.southwest.lng = Math.min(bounds.southwest.lng, marker.longitude);
      bounds.northeast.lat = Math.max(bounds.northeast.lat, marker.latitude);
      bounds.northeast.lng = Math.max(bounds.northeast.lng, marker.longitude);
  });

  return bounds;
}

@Component({
  selector    : 'cc-map',
  templateUrl : './cc-map.component.html',
  styleUrl    : './cc-map.component.scss',
})
export class CcMap implements OnChanges {

    @Input() public inputType?: string = 'road';
    @Input() public inputLong?: number;
    @Input() public inputLat?: number;
    @Input() public markerLocations: { latitude : number, longitude: number, level: number }[] = [];
    @Input() public inputReferencedObjects: SiteModel[] = [];

    @Input()
    public inputPopupTemplate!: TemplateRef<any>;



    private viewContainerRef = inject(ViewContainerRef);

    private globalRedirect = inject(GlobalRedirectService)



    @ViewChild('map', { static: true })
    public mapContainer!: ElementRef;


    public mapReference!: atlas.Map;


    public ngOnChanges() {

      const collection              = this.buildMarkerLocation();
      const {latitude, longitude}   = calculateCenter(collection);
      const bounds                  = calculateBounds(collection);

      const boundingBox = new atlas.data.BoundingBox([
        bounds.southwest.lng,
        bounds.southwest.lat,
        bounds.northeast.lng,
        bounds.northeast.lat
      ]);

      this.mapReference = new atlas.Map(this.mapContainer.nativeElement, {
        center                : [longitude, latitude],
        zoom                  : (this.inputType == 'landmark') ? 18 : 12,
        maxZoom               : 18,
        style                 : (this.inputType == 'landmark') ? 'satellite_road_labels' : this.inputType             ,
        interactive           : true,
        scrollZoomInteraction : true,
        dragPanInteraction    : true,
        authOptions: {
            authType        : AuthenticationType.subscriptionKey,
            subscriptionKey : "G2HrrpHBk1oFvAnB39DFtULTJbD8XDwkJyWWUXRhvuROvbTo6JUvJQQJ99AJACYeBjFZ6F0yAAAgAZMP2OnW"
        }
      });

      const htmlMarkerCollection    = this.buildMarkerCollection(collection);

      if(htmlMarkerCollection && htmlMarkerCollection.length > 1) {

        this.mapReference.setCamera({
          padding : 50,
          bounds  : boundingBox
        });
      }

      this.mapReference.markers.add(htmlMarkerCollection);
    }


    /**
     * @author Mihail Petrov
     * @returns
     */
    public buildMarkerLocation() {

      if(this.inputLat && this.inputLong) {
        return [{ latitude: this.inputLat, longitude: this.inputLong }];
      }

      return this.markerLocations;
    }

    /**
     * @author Mihail Petrov
     * @param collection
     * @returns
     */
    public buildMarkerCollection(collection: any[]) {

      const markerCollection: any = [];

      for(let i = 0; i < collection.length; i++) {

        const location  = collection[i];
        const site      = this.inputReferencedObjects[i];


        const popupReference     = new atlas.Popup({
          content     : `
            <div style="padding: 8px;">
                <p style="border-bottom: solid #dedede 1px; margin-bottom: 16px; font-size: 14px; font-weight: bold">
                  ${site?.data?.name}
                </p>

                <div class="flex">
                  <div style="color: #226092; width: 80px">Address:</div>
                  <div>${site?.data?.address}</div>
                </div>

                <div class="flex mt8">
                  <div style="color: #226092; width: 80px">Roi NPV:</div>
                  <div>${formatCurrency(site?.data?.roiNpv, 'en-US', '$', 'USD', '1.0-0')}</div>
                </div>

                <div class="flex mt8">
                  <div style="color: #226092; width: 80px">On NAVI:</div>
                  <div>${site?.data?.onNeviRoute ? 'YES' : 'NO'}</div>
                </div>

                <div style="width: 100%" data-url="${site?.id}" id="element-${i}" class="btn btn-primary mt16">Go to site</div>

            </div>`,
          pixelOffset: [0, -30],
        });

        const marker    = new atlas.HtmlMarker({
          position  : [location?.longitude, location?.latitude],
          color     : this.getColor(location),
          popup     : popupReference
        });


        this.mapReference.events.add('open', popupReference, () => {

            const result = document.getElementById(`element-${i}`);

            result?.addEventListener('click', () => {

              const id = result?.getAttribute('data-url');
              this.globalRedirect.inContextOfApplication().viewSite(id!);
            })
        });


        this.mapReference.events.add('click', marker, () => {
          marker.togglePopup();
        });

        markerCollection.push(marker);
      }

      return markerCollection;
    }


    private getColor(location: any) {

      const level = location?.level;

      if (level === 0) return '#f0db4f';
      if (level === 1) return '#226092';
      if (level === 2) return '#d91c5c';


      return 'blue';
    }
}
