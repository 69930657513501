[
  { "name": "Alabama"         , "code": "AL", "postalRangeStart": "35004", "postalRangeEnd": "36925" },
  { "name": "Alaska"          , "code": "AK", "postalRangeStart": "99501", "postalRangeEnd": "99950" },
  { "name": "Arizona"         , "code": "AZ", "postalRangeStart": "85001", "postalRangeEnd": "86556" },
  { "name": "Arkansas"        , "code": "AR", "postalRangeStart": "71601", "postalRangeEnd": "72959" },
  { "name": "California"      , "code": "CA", "postalRangeStart": "90001", "postalRangeEnd": "96162" },
  { "name": "Colorado"        , "code": "CO", "postalRangeStart": "80001", "postalRangeEnd": "81658" },
  { "name": "Connecticut"     , "code": "CT", "postalRangeStart": "06001", "postalRangeEnd": "06928" },
  { "name": "Delaware"        , "code": "DE", "postalRangeStart": "19701", "postalRangeEnd": "19980" },
  { "name": "Florida"         , "code": "FL", "postalRangeStart": "32003", "postalRangeEnd": "34997" },
  { "name": "Georgia"         , "code": "GA", "postalRangeStart": "30002", "postalRangeEnd": "39901" },
  { "name": "Hawaii"          , "code": "HI", "postalRangeStart": "96701", "postalRangeEnd": "96898" },
  { "name": "Idaho"           , "code": "ID", "postalRangeStart": "83201", "postalRangeEnd": "83877" },
  { "name": "Illinois"        , "code": "IL", "postalRangeStart": "60001", "postalRangeEnd": "62999" },
  { "name": "Indiana"         , "code": "IN", "postalRangeStart": "46001", "postalRangeEnd": "47997" },
  { "name": "Iowa"            , "code": "IA", "postalRangeStart": "50001", "postalRangeEnd": "52809" },
  { "name": "Kansas"          , "code": "KS", "postalRangeStart": "66002", "postalRangeEnd": "67954" },
  { "name": "Kentucky"        , "code": "KY", "postalRangeStart": "40003", "postalRangeEnd": "42788" },
  { "name": "Louisiana"       , "code": "LA", "postalRangeStart": "70001", "postalRangeEnd": "71497" },
  { "name": "Maine"           , "code": "ME", "postalRangeStart": "03901", "postalRangeEnd": "04992" },
  { "name": "Maryland"        , "code": "MD", "postalRangeStart": "20588", "postalRangeEnd": "21930" },
  { "name": "Massachusetts"   , "code": "MA", "postalRangeStart": "01001", "postalRangeEnd": "05544" },
  { "name": "Michigan"        , "code": "MI", "postalRangeStart": "48001", "postalRangeEnd": "49971" },
  { "name": "Minnesota"       , "code": "MN", "postalRangeStart": "55001", "postalRangeEnd": "56763" },
  { "name": "Mississippi"     , "code": "MS", "postalRangeStart": "38601", "postalRangeEnd": "39776" },
  { "name": "Missouri"        , "code": "MO", "postalRangeStart": "63001", "postalRangeEnd": "65899" },
  { "name": "Montana"         , "code": "MT", "postalRangeStart": "59001", "postalRangeEnd": "59937" },
  { "name": "Nebraska"        , "code": "NE", "postalRangeStart": "68001", "postalRangeEnd": "69367" },
  { "name": "Nevada"          , "code": "NV", "postalRangeStart": "88901", "postalRangeEnd": "89883" },
  { "name": "New Hampshire"   , "code": "NH", "postalRangeStart": "03031", "postalRangeEnd": "03897" },
  { "name": "New Jersey"      , "code": "NJ", "postalRangeStart": "07001", "postalRangeEnd": "08989" },
  { "name": "New Mexico"      , "code": "NM", "postalRangeStart": "87001", "postalRangeEnd": "88439" },
  { "name": "New York"        , "code": "NY", "postalRangeStart": "00501", "postalRangeEnd": "14925" },
  { "name": "North Carolina"  , "code": "NC", "postalRangeStart": "27006", "postalRangeEnd": "28909" },
  { "name": "North Dakota"    , "code": "ND", "postalRangeStart": "58001", "postalRangeEnd": "58856" },
  { "name": "Ohio"            , "code": "OH", "postalRangeStart": "43001", "postalRangeEnd": "45999" },
  { "name": "Oklahoma"        , "code": "OK", "postalRangeStart": "73001", "postalRangeEnd": "74966" },
  { "name": "Oregon"          , "code": "OR", "postalRangeStart": "97001", "postalRangeEnd": "97920" },
  { "name": "Pennsylvania"    , "code": "PA", "postalRangeStart": "15001", "postalRangeEnd": "19640" },
  { "name": "Rhode Island"    , "code": "RI", "postalRangeStart": "02801", "postalRangeEnd": "02940" },
  { "name": "South Carolina"  , "code": "SC", "postalRangeStart": "29001", "postalRangeEnd": "29945" },
  { "name": "South Dakota"    , "code": "SD", "postalRangeStart": "57001", "postalRangeEnd": "57799" },
  { "name": "Tennessee"       , "code": "TN", "postalRangeStart": "37010", "postalRangeEnd": "38589" },
  { "name": "Texas"           , "code": "TX", "postalRangeStart": "73301", "postalRangeEnd": "88595" },
  { "name": "Utah"            , "code": "UT", "postalRangeStart": "84001", "postalRangeEnd": "84791" },
  { "name": "Vermont"         , "code": "VT", "postalRangeStart": "05001", "postalRangeEnd": "05907" },
  { "name": "Virginia"        , "code": "VA", "postalRangeStart": "20101", "postalRangeEnd": "24658" },
  { "name": "Washington"      , "code": "WA", "postalRangeStart": "98001", "postalRangeEnd": "99403" },
  { "name": "West Virginia"   , "code": "WV", "postalRangeStart": "24701", "postalRangeEnd": "26886" },
  { "name": "Wisconsin"       , "code": "WI", "postalRangeStart": "53001", "postalRangeEnd": "54990" },
  { "name": "Wyoming"         , "code": "WY", "postalRangeStart": "82001", "postalRangeEnd": "83414" }
]
