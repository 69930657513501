import { Injectable       } from '@angular/core';
import { BehaviorSubject  } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private apiCount            = 0;
  private isLoadingSubject    = new BehaviorSubject<boolean>(false);
  private hasErrorsSubject    = new BehaviorSubject<boolean>(false);

  public isLoading$           = this.isLoadingSubject.asObservable();
  public hasErrors$           = this.hasErrorsSubject.asObservable();

  public errorValue!: any;

  /**
   * @author Mihail Petrov
   */
  public showLoader() {

    if (this.apiCount === 0) {
      this.isLoadingSubject.next(true);
    }

    this.apiCount++;
  }


  /**
   * @author Mihail Petrov
   * @param errorObject
   */
  public showErrorModal(errorObject: any) {

    this.errorValue = errorObject;
    this.hasErrorsSubject.next(true);
  }


  /**
   * @author Mihail Petrov
   */
  public hideLoader() {

    this.apiCount--;

    if (this.apiCount === 0) {
      this.isLoadingSubject.next(false);
    }
  }
}
