<div class="page-title mb32">
  <h1>{{'@@DASHBOARD.PAGE.TITLE' | translate}}</h1>
</div>

<div class="mt32">

  @if($$ui.state.hasCustomer)
  {
    <div class="btn btn-primary" (click)="processOnCreateNewCustomer()">
      <i class="pi pi-plus"></i> {{ '@@DASHBOARD.ACTION.CREATE' | translate}}
    </div>

    <!-- <div class="btn btn-plane ml32" (click)="processOnImportNewCustomer()">
      <i class="pi pi-upload"></i> {{ '@@DASHBOARD.ACTION.IMPORT' | translate}}
    </div> -->

    <div class="mt32">
      <cc-table
        [inputTableMapping]   = "$$ui.customerMapping"
        [inputDataSource]     = "customerCollection"
        [inputHasPaging]      = "true"

        (onEdit)              = "processOnCustomerEdit($event)"
        (onDelete)            = "processOnCustomerDelete($event)"
        (onNavigate)          = "processOnCustomerNavigate($event)"
        (onNext)              = "processOnChangePage($event)"
        (onPrev)              = "processOnChangePage($event)"
        (onSearch)            = "processOnSearchCustomer($event)">
      </cc-table>
    </div>
  }

  @if(!$$ui.state.hasCustomer)
  {
    <div class="no-data-found">

      <i class="pi pi-user fs46"></i>
      <div class="fsb32 mt8">{{ '@@DASHBOARD.NO_DATA_FOUND.TITLE' | translate}} </div>
      <div class="fs16 mt16">{{ '@@DASHBOARD.NO_DATA_FOUND.SUBTITLE' | translate}}</div>

      <div class="mt32">
          <div (click)="processOnCreateNewCustomer()" class="btn btn-primary">
            <i class="pi pi-plus"></i> {{ '@@DASHBOARD.ACTION.CREATE' | translate}}
          </div>

          <!-- <div (click)="processOnImportNewCustomer()"  class="btn btn-plane ml32">
            <i class="pi pi-upload"></i> {{ '@@DASHBOARD.ACTION.IMPORT' | translate}}
          </div> -->
      </div>
    </div>
  }
</div>

<!-- Manage single customer -->
<sidebar-manage-customers
  [(inputIsVisible)]    = "$$ui.state.isSidebarCreateNewCustomerVisible"
  [inputData]           = "$$ui.data.sidebarTransactionObject"
  [inputSidebarAction]  = "$$ui.data.sidebarTransactionAction"
  (onReturnObject)      = "saveCustomer($event)">
</sidebar-manage-customers>
