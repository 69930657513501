<!-- {{inputForm.get(inputField)?.invalid}}
{{inputForm.get(inputField)?.dirty}}
{{inputForm.get(inputField)?.touched}} -->

@if(inputForm.get(inputField)?.invalid && (inputForm.get(inputField)?.dirty || inputForm.get(inputField)?.touched))
{

  <!--  -->
 @if(inputForm.get(inputField)?.errors?.['required'])
 {
    <div class="red">
      {{'@@CC_FORM_ERROR.REQUIRED' | translate }}
    </div>
 }

 <!--  -->
 @if(inputForm.get(inputField)?.errors?.['pattern'])
 {
  @if(inputValidatorType == 'email')
  {
    <div class="red">
      {{'@@CC_FORM_ERROR.PATTER_EMAIL' | translate }}
    </div>
  }

  @if(inputValidatorType == 'zip')
  {
    <div class="red">
      {{'@@CC_FORM_ERROR.PATTER_ZIP' | translate }}
    </div>
  }

 }

}
