import { Injectable           } from "@angular/core";
import CountryPhoneCollection   from 'assets/collections/geo.country-code.json';

@Injectable({providedIn: 'root'})
export class CountryCollectionService {

  /**
   *
   * @returns
   */
  public getAllCountryPhoneCodes() {
    return CountryPhoneCollection as any;
  }
}
