import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
  standalone  : true,
  selector    : '[actionEnter]'
})
export class ActionEnterDirective {

  @Output() onEnter = new EventEmitter<void>();


  @HostListener('window:keydown.enter', ['$event'])
  onKeydown(event: KeyboardEvent): void {

    console.log("EXECUTE");

    event.preventDefault();
    this.onEnter.emit();
  }
}
