import {  Component     ,
          Input         } from "@angular/core";
import {  CommonModule  } from "@angular/common";
import {  TranslatePipe } from "nv@services/translate.service";

@Component({
  standalone  : true,
  selector    : 'template-summary',
  templateUrl : './template-summary.component.html',
  styleUrl    : './template-summary.component.scss',
  imports     : [
    CommonModule  ,
    TranslatePipe
  ]
})
export class TemplateSummary {

  @Input() public inputData!: any;
}
