import { inject, Injectable } from "@angular/core";
import { CcTableModel       } from "nv@components/cc-table/cc-table.model";
import { TranslateService   } from "nv@services/translate.service";

@Injectable()
export class DashboardState {

  private ts = inject(TranslateService);

  public state = {
    isSidebarCreateNewCustomerVisible : false,
    isSidebarImportNewCustomerVisible : false,
    hasCustomer                       : false
  }

  public data = {
    sidebarTransactionObject  : null,
    sidebarTransactionAction  : '',
    pageId                    : 1
  };

  public customerMapping: CcTableModel[]    = [
    { column: this.ts.translate('@@DASHBOARD.TABLE.CUSTOMER_NAME'),
      value: 'name'                 , isNavigatable: true },
    { column: this.ts.translate('@@DASHBOARD.TABLE.NUMBER_OF_PROJECTS'),
      value: 'numberOfOpenProjects' , isNavigatable: false }
  ];
}
