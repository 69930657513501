import { Injectable         } from "@angular/core";
import { Observable         } from "rxjs";
import { ApiResponseModel   ,
         HttpApiService     } from "nv@services/http.service";
import { ReportRequest      } from "nv@models/report.model";

@Injectable({
  providedIn: 'root'
})
export class ReportApi extends HttpApiService {

  /**
   * @author Mihail Petrov
   * @param $reportRequest
   * @returns
   */
  public getReportForEntity($reportRequest: ReportRequest): Observable<ApiResponseModel>  {

    return this.forEndpoint(`application`, `reports`)
                .withBody($reportRequest)
                .post();
  }

  /**
   * @author Mihail Petrov
   * @param $id
   * @returns
   */
  public getSummaryReport($id: string) {
    return this.getReportForEntity({ entityId: $id, reportType: 'summary'});
  }

  /**
   * @author Mihail Petrov
   * @param $id
   * @returns
   */
  public getSiteReport($id: string) {
    return this.getReportForEntity({ entityId: $id, reportType: 'site' });
  }
}
