<cc-sidebar
  [(inputIsVisible)]  = "inputIsVisible"
  (onHide)            = "processHideSidebar()"
  [inputTitle]        = "sidebarTitle">

  <div>

    @if(inputData && inputData.length > 0)
    {
      @for(reportElement of inputData; track reportElement)
      {

      <div (click)="returnToParent(reportElement)" style="width: 100%;" class="btn btn-plane mb16">
          <div>
            <i class="pi pi-file-pdf"></i>
            {{reportElement?.name}}
          </div>
      </div>

      }
    }
    @else
    {
      <div>
        <div class="no-data-found" style="width: 100%; height: 50%; margin-top: 0; padding-top: 32px;">
        <i class="pi pi-flag fs46"></i>
        <div class="fsb32 mt8">{{'@@MANAGE_REPORTS.NO_DATA_FOUND.TITLE' | translate }}  </div>
        <div class="fs16 mt16">{{'@@MANAGE_REPORTS.NO_DATA_FOUND.SUBTITLE' | translate }}</div>
        </div>
      </div>
    }


  </div>


</cc-sidebar>
