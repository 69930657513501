import {  inject                  } from "@angular/core";
import {  ActivatedRouteSnapshot  ,
          ResolveFn               ,
          RouterStateSnapshot     } from "@angular/router";
import {  lastValueFrom           } from "rxjs";
import {  SiteApi                 } from "nv@api/site.api.service";
import {  SiteModel               } from "nv@models/site.model";
import {  GlobalRedirectService   } from "app/@routes/redirect.service";

export class SiteResolverModel {
  public projectId!: string;
  public siteModel!: SiteModel
  public reportCollection!: []
}

/**
 * @author Mihail Petrov
 * @param route
 * @param state
 * @returns Observable
 */
export const SiteResolverCreateNewSite: ResolveFn<any> = async (
  route: ActivatedRouteSnapshot, state: RouterStateSnapshot
) => {

  const globalRedirect            = inject(GlobalRedirectService)
  const projectId: string | null  = route.paramMap.get('id');

  if(projectId == null) {
    return globalRedirect.inContextOfApplication().redirectTo404();
  }

  return { projectId, siteModel : null };
};

/**
 * @author Mihail Petrov
 * This resolver is used to get infromation about existing site
 * based soly on the site data
 * @param route
 * @param state
 * @returns
 */
export const SiteResolverEditSite: ResolveFn<any> = async (
  route: ActivatedRouteSnapshot, state: RouterStateSnapshot
) => {

  const globalRedirect          = inject(GlobalRedirectService)
  const $siteApi                = inject(SiteApi)
  const siteId: string | null   = route.paramMap.get('id');

  if(siteId == null) {
    return globalRedirect.inContextOfApplication().redirectTo404();
  }

  try {
    const siteModel: SiteModel = (await lastValueFrom($siteApi.getSite(siteId)))?.data
    const projectId: string    = siteModel.projectId as string;

    return { projectId, siteModel };
  }
  catch(error) {
    return globalRedirect.inContextOfApplication().redirectTo404();
  }
};
