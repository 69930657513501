@if(inputIsVisible)
{
  <div
    class="card-box"
    [ngClass]="inputData.roiPeriod == 0 ? 'bg-red' : 'bg-green'">

    <div>
      <i class="pi pi-wallet fs32 m5"></i>
    </div>

    <div class="ml16">
      @if(inputData.roiPeriod == 0)
      {

      <div class="fsb24">
        <div>{{ "@@SITE.TEMPLATE.ROI.NON_RECOVERABLE" | translate }}</div>
      </div>
      }

      @if(inputData?.roiPeriod > 0)
      {
      <div class="fsb18">
        If you install {{ inputData.numberOfPorts }} ports at this location:
      </div>

      <div class="just-flex mt8">
        <div style="width: 156px">
          {{ "@@SITE.TEMPLATE.ROI.ROI_PERIOD_IS" | translate }}
        </div>
        <i class="pi pi-angle-double-right fs12 mt8 ml8 mr8"></i>
        <div class="fs18">{{ inputData.roiPeriod }} years</div>
      </div>

      <!-- @@@ -->
      <div class="just-flex mt8">
        <div style="width: 156px">
          {{ "@@SITE.TEMPLATE.ROI.EXPECTED_NPV" | translate }}
        </div>
        <i class="pi pi-angle-double-right fs12 mt8 ml8 mr8"></i>
        <div class="fs18">
          {{ inputData?.roiNpv | currency : "USD" : "symbol" : "1.0" }}
        </div>
      </div>


      <div class="just-flex mt8">
        <div style="width: 156px">
          {{ "@@SITE.TEMPLATE.ROI.PROFIT" | translate }}
        </div>
        <i class="pi pi-angle-double-right fs12 mt8 ml8 mr8"></i>
        <div class="fs18">
          {{ inputData?.roiProfit | currency : "USD" : "symbol" : "1.0" }}
        </div>
      </div>

      <div class="just-flex mt8">
        <div style="width: 156px">
          {{ "@@SITE.TEMPLATE.ROI.REVENUE" | translate }}
        </div>
        <i class="pi pi-angle-double-right fs12 mt8 ml8 mr8"></i>
        <div class="fs18">
          {{ inputData?.roiRevenue | currency : "USD" : "symbol" : "1.0" }}
        </div>
      </div>

      <div class="mt8" style="border-top: solid #dedede 1px;"></div>

      }

      <div class="just-flex mt8">
        <div style="width: 156px">
          {{ "@@SITE.TEMPLATE.ROI.EXPECTED_VISITORS" | translate }}
        </div>
        <i class="pi pi-angle-double-right fs12 mt8 ml8 mr8"></i>
        <div class="fs18">{{ inputData?.roiAvgWeeklyVisits }}</div>
      </div>

      <div class="just-flex mt8">
        <div style="width: 156px">
          {{ "@@SITE.TEMPLATE.ROI.EXPECTED_CONSUMPTION" | translate }}
        </div>
        <i class="pi pi-angle-double-right fs12 mt8 ml8 mr8"></i>
        <div class="fs18">
          {{ inputData?.roiWeeklyEnergyConsumption | number : "1.0-0" }} kWh
        </div>
      </div>

    </div>
  </div>

  @if(inputData?.suggestedNumberOfPorts)
  {
  <div class="card-box" style="background: rgba(240, 219, 79, 0.5);">
    <div>
      <i class="pi pi-exclamation-triangle fs32 m5"></i>
    </div>

    <div class="ml16 mt8">
      {{ "@@SITE.TEMPLATE.ROI.SUGGESTED_PORTS" | translate }}
      <span style="font-size: 20px; font-weight: bold; margin-left: 8px">{{
        inputData?.suggestedNumberOfPorts
      }}</span>
    </div>
  </div>
  }

}
@else
{
<div class="cc-placeholder">
  <div>
    <i class="pi pi-wallet fs32 m5"></i>
  </div>
  <div class="ml16 fs24">{{ "@@SITE.TEMPLATE.ROI.NAME" | translate }}</div>
</div>
}
