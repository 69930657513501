@if(inputIsVisible)
{
  <div class="card-box">

    <div>
      <i class="pi pi-map-marker fs32 m5"></i>
    </div>

    <div class="ml16">
      <div class="fsb18">{{inputData?.address}}</div>
      <div class="just-flex">
        <div class="pill">{{inputData?.zip}}</div>
        <div>{{inputData?.state}}</div>
        <i class="fs12 m8 pi pi-angle-double-right"></i>
        <div>{{inputData?.city}}</div>
      </div>
    </div>
  </div>
}
@else
{
  <div class="cc-placeholder">
    <div>
      <i class="pi pi-map-marker fs32 m5"></i>
    </div>
    <div class="ml16 fs24"> {{'@@SITE.TEMPLATE.SITE_LOCATION.NAME' | translate}} </div>
  </div>
}
