<p-sidebar
  [(visible)]   = "inputIsVisible"
  (onHide)      = "processHideSidebar()"
  [style]       = "{width:'35em', height: '100%', background: '#fff'}"
  [dismissible] = "inputCloseOnByClick"
  position      = "right">

  <ng-template pTemplate="header">
    <div class="pf4d-p-header">
      <div class="pf4d-p-header--icon">
        <i class="fa fa-building"></i>
      </div>

      <div class="pf4d-p-header--title">
        {{ inputTitle }}
      </div>
    </div>
  </ng-template>

  <ng-template pTemplate="content">
    <div style="margin-left: 16px; height: 100%;">
      <ng-content></ng-content>
    </div>

  </ng-template>

</p-sidebar>
