<!-- Spinner icon -->
<div *ngIf="(loader.isLoading$ | async)" class="spinner-overlay">
  <img style="width: 64px; height: 64px;" src="/assets/images/loader-logo-gif.gif"/>
</div>

<!-- Error dialog -->
<p-dialog
  [(visible)]  = "isVisible"
  [closable]   = "true"
  [draggable]  = "false"
  [modal]      = "true"
  [showHeader] = "false"
  [position]   = "'center'"
  [style]      = "{ width: '35rem' }">

  <div class="tac p16">
    <i class="pi pi-exclamation-triangle fs32"></i>
    <div class="fs24">Something went wrong</div>
  </div>

  <div class="mt16">

    @if(loader.errorValue?.error_message)
    {
      {{ loader.errorValue?.error_message }}
    }

    @if(loader.errorValue?.errorMessage)
    {
      {{ loader.errorValue?.errorMessage }}
    }

  </div>

  <div (click)="isVisible = false" style="text-align: center;" class="btn btn--wide btn-plane mt32">
    Continue
  </div>

</p-dialog>
