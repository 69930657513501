import {  Component           ,
          inject              ,
          OnChanges           } from "@angular/core";
import {  FormControl         ,
          ReactiveFormsModule ,
          Validators          } from "@angular/forms";
import {  SidebarModule       } from 'primeng/sidebar';

import {  CcFormGroup         } from "app/@shared/forms/form-group/cc-form-group.extention";
import {  CcFormError         } from "nv@components/cc-form-error/cc-form-error.component";
import {  CcBaseSidebar       } from "nv@components/cc-sidebar/cc-sidebar.base";
import {  CcSidebar           } from "nv@components/cc-sidebar/cc-sidebar.component";
import {  TranslatePipe       ,
          TranslateService    } from "nv@services/translate.service";
import { ActionEnterDirective } from "app/@shared/directives/action-enter.directive";
import { TemplateImport } from "./@templates/template-import/template-import.component";

@Component({
  standalone  : true,
  selector    : 'sidebar-manage-customers',
  templateUrl : './manage-customers.component.html',
  styleUrl    : './manage-customers.component.scss',
  imports     : [
    ReactiveFormsModule ,
    SidebarModule       ,
    SidebarModule       ,
    CcSidebar           ,
    CcFormError         ,
    TranslatePipe       ,
    ActionEnterDirective,
    TemplateImport
  ]
})
export class SidebarManageCustomers extends CcBaseSidebar implements OnChanges {

  private ts            = inject(TranslateService);
  public sidebarTitle   = this.ts.translate('@@MANAGE_CUSTOMERS.PAGE.CREATE_CUSTOMER_TITLE');

  public $form = new CcFormGroup({
    name  : new FormControl(null, [Validators.required]),
  });

  public ngOnChanges() {

    if(this.isInCreateMode()) {

      this.sidebarTitle         = this.ts.translate('@@MANAGE_CUSTOMERS.PAGE.CREATE_CUSTOMER_TITLE');
      this.inputCloseOnByClick  = true;
      this.$form.reset();
    }

    if(this.isInEditMode()) {

      this.sidebarTitle         = this.ts.translate('@@MANAGE_CUSTOMERS.PAGE.EDIT_CUSTOMER_TITLE');
      this.inputCloseOnByClick  = true;
      this.$form.controls['name'  ].setValue(this.inputData.name);
    }

    if(this.isInDeleteMode()) {

      this.inputCloseOnByClick  = false;
      this.sidebarTitle         = this.ts.translate('@@MANAGE_CUSTOMERS.PAGE.REMOVE_CUSTOMER_TITLE');
    }

    if(this.isInImportMode()) {

      this.inputCloseOnByClick  = false;
      this.sidebarTitle         = this.ts.translate('@@MANAGE_CUSTOMERS.PAGE.IMPORT_CUSTOMER_TITLE');
    }

  }

  /**
   * @author Mihail Petrov
   */
  public async processForm() {

    this.$form.markFormGroupDirty().isFormValid(() => {

      this.returnToParent({
        ...this.inputData,
        ...this.$form.value
      });
    })
  }

  /**
   * @author Mihail Petrov
   */
  public removeEntity() {
    this.returnToParent(this.inputData);
  }

  /**
   * @author Mihail Petrov
   * @param event
   */
  public processFile(event: any) {

    const file: File  = event.files[0];
    const formData    = new FormData();
    formData.append('file', file, file.name);

    this.returnToParent(formData);
  }
}
