import { CommonModule               } from "@angular/common";
import { Component, inject, OnInit  } from "@angular/core";
import { ActivatedRoute             } from "@angular/router";
import { lastValueFrom              } from "rxjs";

import { GlobalRedirectService      } from "app/@routes/redirect.service";
import { CustomerApi                } from "nv@api/customer.api.service";
import { ProjectApi                 } from "nv@api/project.api.service";
import { SidebarManageContacts      } from "nv@features/@modals/manage-contacts/manage-contacts.component";
import { SidebarManageProjects      } from "nv@features/@modals/manage-projects/manage-projects.component";
import { ProjectModel               } from "nv@models/project.model";
import { ContactModel               } from "nv@models/contact.model";
import { TranslatePipe              } from "nv@services/translate.service";
import { CustomerState              } from "./customers.state";
import { TemplateContactTab         } from "../@templates/template-contact-tab/template-contact-tab.component";
import { TemplateProjectTab         } from "../@templates/template-project-tab/template-project-tab.component";

@Component({
  standalone  : true,
  selector    : 'page-customer',
  templateUrl : './customers.component.html',
  styleUrl    : './customers.component.scss',
  providers   : [CustomerState],
  imports: [
    CommonModule          ,
    SidebarManageProjects ,
    SidebarManageContacts ,

    TranslatePipe         ,
    TemplateContactTab    ,
    TemplateProjectTab
  ]
})
export class CustomerPage implements OnInit {

  public $$ui                 = inject(CustomerState)

  private route               = inject(ActivatedRoute);
  private globalRedirect      = inject(GlobalRedirectService);
  private $customerApiService = inject(CustomerApi);
  private $projectApiService  = inject(ProjectApi);

  public customerDetail!: any;
  public projectCollection!: any[];
  public contactCollection!: any[];

  public async ngOnInit() {

    this.customerDetail     = this.route.snapshot.data['data'];
    await this.actionFetchApi();
  }

  /**
   * @author Mihail Petrov
   */
  public async actionFetchApi() {

    const projectResponse       = await lastValueFrom(this.$customerApiService.getAllProjectsForCustomer(this.customerDetail.id));
    this.projectCollection      = projectResponse.data;
    this.$$ui.state.hasProjects = this.projectCollection && this.projectCollection.length > 0;

    const contactResponse       = await lastValueFrom(this.$customerApiService.getAllContactsForCustomer(this.customerDetail.id));
    this.contactCollection      = contactResponse.data;
    this.$$ui.state.hasContacts = this.contactCollection && this.contactCollection.length > 0;
  }

  public onTabChange(state: string) {
    this.$$ui.state.activeTab = state
  }

  public processOnCreateNewProject() {

    this.$$ui.data.sidebarTransactionAction        = 'create';
    this.$$ui.state.isSidebarManageProjectsVisible = true;
  }

  public processOnImportProject() {

    this.$$ui.data.sidebarTransactionAction        = 'import';
    this.$$ui.state.isSidebarManageProjectsVisible = true;
  }

  /**
   * @author Mihail Petrov
   * @param $event
   */
  public async processOnProjectEdit($event: ProjectModel) {

    this.$$ui.data.sidebarTransactionAction        = 'edit';
    this.$$ui.data.sidebarTransactionObject        = (await lastValueFrom(this.$projectApiService.getProject($event.id!))).data;
    this.$$ui.state.isSidebarManageProjectsVisible = true;
  }

  /**
   * @author Mihail Petrov
   * @param $event
   */
  public processOnProjectDelete($event: any) {

    this.$$ui.data.sidebarTransactionAction        = 'delete';
    this.$$ui.data.sidebarTransactionObject        = $event;
    this.$$ui.state.isSidebarManageProjectsVisible = true;
  }

  /**
   * @author Mihail Petrov
   */
  public processOnCreateNewContact() {

    this.$$ui.data.sidebarTransactionAction        = 'create';
    this.$$ui.state.isSidebarManageContactsVisible = true;
  }

  /**
   * @author Mihail Petrov
   */
  public processOnImportContact() {

    this.$$ui.data.sidebarTransactionAction        = 'import';
    this.$$ui.state.isSidebarManageContactsVisible = true;
  }

  /**
   * @author Mihail Petrov
   * @param $event
   */
  public async processOnContactEdit($event: any) {

    this.$$ui.data.sidebarTransactionAction        = 'edit';
    this.$$ui.data.sidebarTransactionObject        = $event;
    this.$$ui.state.isSidebarManageContactsVisible = true;
  }

  /**
   * @author Mihail Petrov
   * @param $event
   */
  public processOnContactDelete($event: any) {

    this.$$ui.data.sidebarTransactionAction        = 'delete';
    this.$$ui.data.sidebarTransactionObject        = $event;
    this.$$ui.state.isSidebarManageContactsVisible = true;
  }

  /**
   * @author Mihail Petrov
   * @param $entity
   */
  public async processProject($entity: any) {

    if(this.$$ui.data.sidebarTransactionAction == "create"  ) await this.createProject  ($entity);
    if(this.$$ui.data.sidebarTransactionAction == "import"  ) await this.importProject  ($entity);
    if(this.$$ui.data.sidebarTransactionAction == "edit"    ) await this.editProject    ($entity);
    if(this.$$ui.data.sidebarTransactionAction == "delete"  ) await this.removeProject  ($entity);
  }

    /**
     * @author Mihail Petrov
     * @param $entity
     */
    public async createProject($entity: ProjectModel) {

      $entity.customerId  = this.customerDetail.id;
      await lastValueFrom(this.$projectApiService.createNewProject($entity))
      await this.actionFetchApi();
    }

    /**
     *
     * @param $entity
     */
    public async importProject($entity: any) {

      $entity.append('customerId', this.customerDetail.id);

      await lastValueFrom(this.$projectApiService.importProject($entity));
      await this.actionFetchApi();
    }


  /**
   * @author Mihail Petrov
   * @param $entity
   */
  public async editProject($entity: ProjectModel) {

      await lastValueFrom(this.$projectApiService.updateProject($entity))
      await this.actionFetchApi();
  }

  /**
   * @author Mihail Petrov
   * @param $entity
   */
  public async removeProject($entity: ProjectModel) {

      await lastValueFrom(this.$projectApiService.removeProject($entity.id!))
      await this.actionFetchApi();
  }

  /**
   * @author Mihail Petrov
   * @param $entity
   */
  public async processContact($entity: ContactModel) {

    if(this.$$ui.data.sidebarTransactionAction == "create"  ) await this.createContact  ($entity);
    if(this.$$ui.data.sidebarTransactionAction == "import"  ) await this.importContact  ($entity);
    if(this.$$ui.data.sidebarTransactionAction == "edit"    ) await this.editContact    ($entity);
    if(this.$$ui.data.sidebarTransactionAction == "delete"  ) await this.removeContact  ($entity);
  }

  /**
   * @author Mihail Petrov
   * @param $entity
   */
  public async createContact($entity: ContactModel) {

    $entity.customerId = this.customerDetail.id;
    await lastValueFrom(this.$customerApiService.createNewContact($entity))
    await this.actionFetchApi();
  }

  /**
   *
   * @param $entity
   */
  public async importContact($entity: any) {

    $entity.append('customerId', this.customerDetail.id);

    await lastValueFrom(this.$customerApiService.importContact($entity));
    await this.actionFetchApi();
  }

  /**
   * @author Mihail Petrov
   * @param $entity
   */
  public async editContact($entity: ContactModel) {

    await lastValueFrom(this.$customerApiService.updateContact($entity))
    await this.actionFetchApi();
  }

  /**
   * @author Mihail Petrov
   * @param $entity
   */
  public async removeContact($entity: ContactModel) {

    await lastValueFrom(this.$customerApiService.removeContact($entity.id!))
    await this.actionFetchApi();
  }

  /**
   * @author Mihail Petrov
   * @param $pageId
   */
  public async processOnChangeProjectPage($pageId: any) {

    const response          = await lastValueFrom(this.$customerApiService.getAllProjectsForCustomer(this.customerDetail.id, $pageId));
    this.projectCollection  = response.data;
  }

  /**
   * @author Mihail Petrov
   * @param $pageId
   */
  public async processOnChangeContactPage($pageId: any) {

    const response          = await lastValueFrom(this.$customerApiService.getAllContactsForCustomer(this.customerDetail.id, $pageId));
    this.contactCollection  = response.data;
  }

  /**
   * @author Mihail Petrov
   */
  public goHome() {
    this.globalRedirect.inContextOfCustomer().viewAllCustomers();
  }

  /**
   * @author Mihail Petrov
   * @param $searchValue
   */
  public async processOnSearchProject($searchValue: string) {

    const response          = await lastValueFrom(this.$customerApiService.getAllProjectsForCustomer(this.customerDetail.id, {
      name: $searchValue
    }));

    this.projectCollection  = response.data;
  }

  /**
   * @author Mihail Petrov
   * @param $searchValue
   */
  public async processOnSearchContact($searchValue: string) {

    const response          = await lastValueFrom(this.$customerApiService.getAllContactsForCustomer(this.customerDetail.id, {
      fullName: $searchValue
    }));

    this.contactCollection  = response.data;
  }

  /**
   * @author Mihail Petrov
   * @param $event
   */
  public processOnNavigate($event: ProjectModel) {
    if($event.id) this.globalRedirect.inContextOfCustomer().viewProject($event.id);
  }
}
