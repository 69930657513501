import { Router } from "@angular/router";

export class ApplicationRedirectContext {

  private router: Router;
  private reloadPage = true;

  public constructor(router: Router, reloadPage: boolean) {

    this.router     = router;
    this.reloadPage = reloadPage;
  }

  public redirectToLogin() {
    this.router.navigateByUrl('/login');
  }

  public redirectToDashboard() {
    this.router.navigateByUrl('/dashboard');
  }

  public redirectToReports() {
    this.router.navigateByUrl('/reports');
  }

  public redirectToKpi() {
    this.router.navigateByUrl('/kpi');
  }

  public redirectTo404() {
    this.router.navigateByUrl('page-not-found');
  }


  /**
   * @author Mihail Petrov
   * @param $id
   */
  public viewCustomer($id: string) {
    this.router.navigateByUrl(`customers/${$id}`);
  }

  /**
   * @author Mihail Petrov
   * @param $id
   */
  public viewProject($id: string) {
    this.router.navigateByUrl(`projects/${$id}`);
  }


  /**
   * @author Mihail Petrov
   * @param $id
   */
  public viewSite($id: string) {

    if(this.reloadPage) {
      this.router.navigateByUrl(`sites/${$id}`);
    }
    else {
      window.history.replaceState({}, '',`/sites/${$id}`);
    }
  }

}

export class CustomerRedirectContext {

  private router: Router;

  public constructor(router: Router) {
    this.router = router;
  }

  /**
   * @author Mihail Petrov
   */
  public viewAllCustomers() {
    this.router.navigateByUrl('/dashboard')
  }

  /**
   * @author Mihail Petrov
   * @param id
   */
  public viewSpecificCustomer(id: string) {
    this.router.navigateByUrl(`/customers/${id}`)
  }

  /**
   * @author Mihail Petrov
   * @param id
   */
  public viewProject(id: string) {
    this.router.navigateByUrl(`/projects/${id}`);
  }

  /**
   * @author Mihail Petrov
   * @param pid
   */
  public createNewSite(pid: string) {
    this.router.navigateByUrl(`/projects/${pid}/sites`)
  }
}
