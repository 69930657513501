import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  standalone  : true,
  selector    : 'template-delete',
  templateUrl : './template-delete.component.html'
})
export class TemplateDelete {

  @Input() public inputData: any;

  @Output() public onCancel = new EventEmitter();
  @Output() public onDelete = new EventEmitter();
}
