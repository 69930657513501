<div class="flex" style="width: 100%;">

  <!-- Traffic Card -->
  <div class="box">
    <div class="icon mb16">
      <i class="pi pi-car"></i>
      <div class="title">Traffic for this location</div>
    </div>

    <!--  -->
    <div class="flex mt8">
      <div class="title">Expected weekly visitors</div>
      <strong>{{ inputData?.roiAvgWeeklyVisits }}</strong>
    </div>

    <div class="flex mt8">
      <div class="title">Handled weekly visitors</div>
      <strong>{{ inputData?.avgHandledWeeklyVisits }}</strong>
    </div>

    <div class="flex mt8">
      <div class="title">Travel traffic: 0-30 Miles</div>
      <strong>{{ inputData?.trafficThirtyMiles }}</strong>
    </div>

    <div class="flex mt8">
      <div class="title">Travel traffic: 30-70 Miles</div>
      <strong>{{ inputData?.trafficSeventyMiles }}</strong>
    </div>

    <div class="flex mt8">
      <div class="title">Travel traffic: >70 Miles</div>
      <strong>{{ inputData?.trafficAboveSeventy }}</strong>
    </div>
  </div>

  <!-- Score Card -->
  <div class="box">
    <div class="icon mb16">
      <i class="pi pi-car"></i>
      <div class="title">Score for this location: {{ inputData.score }}</div>
    </div>

    @for (distribution of getScoreData(); track $index){
      <div class="flex mt8" style="align-items: center;">
        <div class="title" style="width:200px;">{{distribution.title}}</div>
        <div class="progress-bar">
            <div class="progress-fill"
                [style.width.%]="(distribution.value / getMaxValue($index)) * 100"
                [ngStyle]="{'background-color': getBarColor($index)}">
            </div>
        </div>
      </div>
    }
  </div>

  <!-- Electricity Price Card -->
  <div class="box">
    <div class="icon mb16">
      <i class="pi pi-bolt"></i>
      <div class="title">Electricity Cost for {{inputData?.utilityPlanType}}</div>
    </div>

    @if(inputData?.utilityPlanType == 'fixed'){
      <!--  -->
      <div class="flex mt8">
        <div class="title">Fixed cost (yearly)</div>
        <strong>{{inputData?.fixedMonthlyCost | currency:'USD':'symbol':'1.0-0'}}</strong>
      </div>

      <div class="flex mt8">
        <div class="title">Fixed price</div>
        <strong>{{inputData?.electricityCost | currency:'USD'}}</strong>
      </div>
    }

    @if(inputData?.utilityPlanType == 'tou'){
      <!--  -->
      <div class="flex mt8">
        <div class="title">Fixed cost (yearly)</div>
        <strong>{{inputData?.fixedMonthlyCost | currency:'USD':'symbol':'1.0-0'}}</strong>
      </div>

      <div class="flex mt8">
        <div class="title">On peak</div>
        <strong>{{inputData?.electricityCostPeak | currency:'USD'}}</strong>
      </div>

      <div class="flex mt8">
        <div class="title">Off peak</div>
        <strong>{{inputData?.electricityCostOffPeak | currency:'USD'}}</strong>
      </div>

      <div class="flex mt8">
        <div class="title">Super off peak</div>
        <strong>{{inputData?.electricityCostSuperOffPeak | currency:'USD'}}</strong>
      </div>
    }
  </div>

  <!-- Pricing Card -->
  <div class="box">
    <div class="icon mb16">
      <i class="pi pi-bolt"></i>
      @if(!(inputData?.hasDriverPriceMode)){
        <div class="title">Driver price for {{ inputData?.driverPriceMultiplier ? "multiplier" : ""}}</div>
      }

      @if(inputData?.hasDriverPriceMode){
        <div class="title">Driver fixed sales price</div>
      }
    </div>

    @if(inputData?.utilityPlanType == 'fixed'){
      <div class="flex mt8">
        <div class="title">Driver price</div>
        <strong>{{inputData?.driverPrice | currency:'USD'}}</strong>
      </div>
    }

    @if(inputData?.utilityPlanType == 'tou'){
      @if(inputData?.hasDriverPriceMode){
        <div class="flex mt8">
          <div class="title">Driver price</div>
          <strong>{{inputData?.driverPricePeak | currency:'USD'}}</strong>
        </div>
      }

      @if(!(inputData?.hasDriverPriceMode)){
        <div class="flex mt8">
          <div class="title">On peak</div>
          <strong>{{inputData?.driverPricePeak | currency:'USD'}}</strong>
        </div>

        <div class="flex mt8">
          <div class="title">Off peak</div>
          <strong>{{inputData?.driverPriceOffPeak | currency:'USD'}}</strong>
        </div>

        <div class="flex mt8">
          <div class="title">Super off peak</div>
          <strong>{{inputData?.driverPriceSuperOffPeak | currency:'USD'}}</strong>
        </div>
      }
    }
  </div>

  <!-- ROI Card -->
  <div class="box">
    <div class="icon mb16">
      <i class="pi pi-dollar"></i>
      <div class="title">ROI for {{ inputData.numberOfPorts }} ports</div>
    </div>

    <div class="flex mt8">
      <div class="title">ROI in</div>
      <strong>{{ inputData.roiPeriod | transfromToYearMonthLabel }}</strong>
    </div>

    <div class="flex mt8">
      <div class="title">Profit (Yearly)</div>
      <strong>{{ inputData?.roiProfit | currency : "USD" : "symbol" : "1.0" }}</strong>
    </div>

    <div class="flex mt8">
      <div class="title">Revenue (Yearly)</div>
      <strong>{{inputData?.roiRevenue | currency:'USD':'symbol':'1.0-0'}}</strong>
    </div>

    <div class="flex mt8">
      <div class="title">NPV</div>
      <strong>{{inputData?.roiNpv | currency:'USD':'symbol':'1.0-0'}}</strong>
    </div>

    <div class="flex mt8">
      <div class="title">Avg Weekly Consumption</div>
      <strong>{{ inputData?.roiWeeklyEnergyConsumption | number : "1.0-0" }} kWh</strong>
    </div>
  </div>
</div>
