import { Routes                     } from '@angular/router';
import { MsalGuard, MsalService                  } from '@azure/msal-angular';
import { PageNotFound               } from 'nv@features/404/page-not-found.component';
import { DashboardPage              } from 'nv@features/dashboard/@pages/dashboard.component';
import { CustomerPage               } from 'nv@features/customers/@pages/customers.component';
import { ProjectPage                } from 'nv@features/projects/@pages/project.component';
import { SitePage                   } from 'nv@features/sites/@pages/site.component';
import { CustomerResolver           } from 'nv@features/customers/customer.resolver';
import { ProjectResolver            } from 'nv@features/projects/project.resolver';
import { SiteResolverCreateNewSite  ,
         SiteResolverEditSite       } from 'nv@features/sites/site.resolver';

export const routes: Routes = [
  {
    path        : '',
    redirectTo  : 'dashboard',
    pathMatch   : 'full'
  },
  {
    path        : 'callback'           ,
    redirectTo  : 'dashboard',
    pathMatch   : 'full'
  },
  {
    path        : 'dashboard'     ,
    component   : DashboardPage   ,
    canActivate : [ MsalGuard ]
  },
  {
    path        : 'customers/:id'  ,
    component   : CustomerPage    ,
    canActivate : [ MsalGuard ]   ,
    resolve     : { data: CustomerResolver }
  },
  {
    path        : 'projects/:id'          ,
    component   : ProjectPage             ,
    canActivate : [ MsalGuard ]           ,
    resolve     : { data: ProjectResolver }
  },
  {
    path        : 'projects/:id/sites'    ,
    component   : SitePage                ,
    canActivate : [ MsalGuard ]           ,
    resolve     : { data: SiteResolverCreateNewSite }
  },
  {
    path        : 'sites/:id'           ,
    component   : SitePage              ,
    canActivate : [ MsalGuard ]         ,
    resolve     : { data: SiteResolverEditSite  }
  },

  {
    path          : '**',
    component     : PageNotFound
  },

  {
    path          : 'page-not-found',
    component     : PageNotFound
  }
];
