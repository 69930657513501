import {  Component                   ,
          EventEmitter                ,
          inject                      ,
          OnInit                      ,
          Output                      ,
          input                       } from "@angular/core";
import {  CommonModule                } from "@angular/common";
import {  FormArray                   ,
          FormControl                 ,
          FormGroup                   ,
          ReactiveFormsModule         ,
          Validators                  } from "@angular/forms";
import {  DropdownModule              } from "primeng/dropdown";
import {  ToggleButtonModule          } from 'primeng/togglebutton';
import {  TooltipModule               } from 'primeng/tooltip';
import {  SelectButtonModule          } from "primeng/selectbutton";

import {  StateCollectionService      } from "app/@shared/collections/state.collection.service";
import {  UtilityCollectionService    } from "app/@shared/collections/utility.collection.service";
import {  CcFormGroup                 } from "app/@shared/forms/form-group/cc-form-group.extention";
import {  CcFormError                 } from "nv@components/cc-form-error/cc-form-error.component";
import {  FormUtil                    } from "nv@services/form-util.service";
import {  TranslatePipe               } from "nv@services/translate.service";
import {  TemplateFormHeader          } from "nv@features/sites/@templates/template-form-header/template-form-header.component";
import {  SiteService                 } from "../../@pages/site.service";

import { FormatDirective } from "app/@shared/directives/format-currency.directive";

@Component({
  selector    : 'wizzard-site-config',
  templateUrl : './wizzard-site-config.component.html',
  imports     : [
    CommonModule        ,
    TemplateFormHeader  ,
    ReactiveFormsModule ,
    CcFormError         ,
    DropdownModule      ,
    SelectButtonModule  ,
    ToggleButtonModule  ,
    TranslatePipe       ,
    TooltipModule       ,
    FormatDirective
  ]
})
export class WizzardSiteConfig implements OnInit {

  readonly inputForm                    = input.required<FormGroup>();
  readonly inputParentForm              = input.required<FormArray>();
  readonly isVisible                    = input<boolean>(false);

  @Output() onUtilityDetailsCalculated  = new EventEmitter();
  @Output() onPricingDetailsCalculated  = new EventEmitter();

  public fu: FormUtil                   = inject(FormUtil);
  public $utilityCollectionService      = inject(UtilityCollectionService)
  public $stateCollectionService        = inject(StateCollectionService)
  private SiteService                   = inject(SiteService);

  public utilityPlanGroupCollection: any;
  public utilityCollection: any;
  public utilityPlanCollection!: any;
  public ratePlanCollection: any;

  public $form                      = new CcFormGroup({

    driverPriceMultiplier           : new FormControl<number | null>(1, [Validators.required]),
    hasDriverPriceMode              : new FormControl<boolean>(true), // Toggle control
    utilization                     : new FormControl<number|null>(100),
    incentives                      : new FormControl<number|null>(0),
    hasIncentives30c                : new FormControl<boolean|null>(false),
    hasIncentivesBABA               : new FormControl<boolean|null>(false),

    hardwareCost                    : new FormControl<number|null>(0),
    hardwareCostByConfiguration     : new FormControl<number|null>(null),

    installationCost                : new FormControl<number|null>(0),
    installationCostByConfiguration : new FormControl<number|null>(null),
    operatingCost                   : new FormControl<number|null>(0, [Validators.required]),

    // hidden
    numberOfPortsForSelectedUnit    : new FormControl<number|null>(2),
    numberOfPorts                   : new FormControl<number|null>(2),

    hardwareConfigCollection        : new FormArray([new CcFormGroup({
      hardwareConfiguration         : new FormControl<string|null>(null , [Validators.required]),
      numberOfUnits                 : new FormControl<number|null>(1    , [Validators.required]),
      numberOfPorts                 : new FormControl<number|null>(1),
      hardwareCapacity              : new FormControl<number|null>(600),
      hasNACScable                  : new FormControl<boolean>(false)
    }) ]),

    monthlySubscription             : new FormControl<number|null>(0    , [Validators.required]),
    utility                         : new FormControl<number|null>(null , [Validators.required]),
    utilityPlanGroup                : new FormControl<string|null>(null , [Validators.required]),
    utilityPlan                     : new FormControl<string|null>(null , [Validators.required]),
    utilityPlanId                   : new FormControl<string|null>(null , [Validators.required]),

    // UTILITY - hidden
    utilityPlanType                 : new FormControl<string|null>(null),
    electricityCost                 : new FormControl<number|null>(null),
    electricityCostPeak             : new FormControl<number|null>(null),
    electricityCostOffPeak          : new FormControl<number|null>(null),
    electricityCostSuperOffPeak     : new FormControl<number|null>(null),

    // PRICING - hidden
    driverPrice                     : new FormControl<number|null>(0.45),
    driverPricePeak                 : new FormControl<number|null>(null),
    driverPriceOffPeak              : new FormControl<number|null>(null),
    driverPriceSuperOffPeak         : new FormControl<number|null>(null),

    // hidden
    utilityCost                       : new FormControl<object|null>(null),
    isHardwareCostManuallyChanged     : new FormControl<boolean>(false),
    isInstallationCostManuallyChanged : new FormControl<boolean>(false),

    fixedMonthlyCost                  : new FormControl<number|null>(null),
  });

  public state = {
    utilityUrl: null
  }

  public async ngOnInit(): Promise<void> {

    this.inputParentForm().push(this.$form);

    this.SiteService.serviceProcessor$.subscribe((result: any) => {

      const $action = result.formKey;
      const $value  = result.value;

      if($action == 'process@HardwareConfig' ) this.processHardwareConfigurationCollection($value);
      if($action == 'process@UTILITY'        ) this.processUtilityCollection($value);
      if($action == 'ratePlanCollection'     ) this.processRatePlanCollection($value);
      if($action == 'selectRatePlan'         ) this.processUtilityPlanSelection($value);
      if($action == 'process@DRIVER_PRICE'   ) this.processDriverPriceMultiplierChange();

      if($action == 'selectUtilityGroup') {

        this.utilityPlanGroupCollection  = Object.keys(this.ratePlanCollection).map((element: any) => {
          return {name: element, value: element }
        });

        this.utilityPlanCollection = Object.keys(this.ratePlanCollection[$value.utilityPlanGroup]).map((planElement: any) => {
          return { name : planElement, value : planElement  }
        });

        this.processUtilityPlanSelection($value.utilityPlan)
      }
    });
  }

  /**
   * Handles toggling between driver price and multiplier mode.
   */
  public toggleDriverPriceMode(event: any) {
    this.$form.get("hasDriverPriceMode")?.setValue(!(this.$form.get("hasDriverPriceMode")?.value));
    this.processDriverPriceMultiplierChange();
  }

  /**
   * Converts the manually entered driver price into a multiplier.
   */
  public convertDriverPriceToMultiplier() {
    const planType   = this.$form.get('utilityPlanType')?.value;
    const typedValue = this.$form.get('driverPrice')?.value ?? 0;

    if (typedValue <= 0) {
      return;
    }

    if (planType === 'fixed') {
      // Standard fixed logic:
      const electricityCost = this.$form.get('electricityCost')?.value ?? 0;
      if (electricityCost > 0) {
        const newMultiplier = parseFloat((typedValue / electricityCost).toFixed(2));
        this.$form.get('driverPriceMultiplier')?.setValue(newMultiplier);

        this.processDriverPriceMultiplierChange();
      }
    } 
    else if (planType === 'tou') {
      // Treat 'driverPrice' as the "peak" price
      const electricityCostPeak = this.$form.get('electricityCostPeak')?.value ?? 0;
      if (electricityCostPeak > 0) {
        this.$form.get('driverPricePeak')?.setValue(typedValue);

        const newMultiplier = parseFloat((typedValue / electricityCostPeak).toFixed(2));
        this.$form.get('driverPriceMultiplier')?.setValue(newMultiplier);

        this.processDriverPriceMultiplierChange();
      }
    }
  }

  /**
   * @author Mihail Petrov
   * @returns
   */
  public getHardwareCollection() {
    return (this.$form.get('hardwareConfigCollection') as FormArray).controls;
  }


  /**
   * @author Mihail Petrov
   */
  public lockHardwareCost() {
    this.$form.get('isHardwareCostManuallyChanged')?.setValue(true);
  }

  /**
   * @author Mihail Petrov
   */
  public unlockHardwareCost() {

    this.$form.get('isHardwareCostManuallyChanged')?.setValue(false);
    this.processHardwareConfiguration();
  }


  /**
   * @author Mihail Petrov
   */
  public lockInstallationCost() {
    this.$form.get('isInstallationCostManuallyChanged')?.setValue(true);
  }

  /**
   * @author Mihail Petrov
   */
  public unlockInstallationCost() {

    this.$form.get('isInstallationCostManuallyChanged')?.setValue(false);
    this.processHardwareConfiguration();
  }

  /**
   * @author Mihail Petrov
   */
  public addNewHardwareConfig(): void {

    const hardwareConfigCollection = (this.$form.get('hardwareConfigCollection') as FormArray);

    hardwareConfigCollection.push(new CcFormGroup({
      hardwareConfiguration           : new FormControl<string|null>(null , [Validators.required]),
      numberOfUnits                   : new FormControl<number|null>(1    , [Validators.required]),
      numberOfPorts                   : new FormControl<number|null>(1)   ,
      hardwareCapacity                : new FormControl<number|null>(600) ,
      hasNACScable                    : new FormControl<boolean>(false)
    }));
  }

  /**
   * @author Mihail Petrov
   * @param $index
   */
  public removeHardwareConfig($index: number): void {

    (this.$form.get('hardwareConfigCollection') as FormArray).removeAt($index);
    this.processHardwareConfiguration();
  }

  /**
   * @author Mihail Petrov
   * @param value
   */
  public async processUtilityProviderSelection(value: string) {

    this.$form.get('utilityPlan'      )?.setValue('');
    this.$form.get('utilityPlanGroup' )?.setValue('');
    this.utilityPlanCollection      = [];
    this.utilityPlanGroupCollection = [];

    this.SiteService.sendData('select_utility');
  }

  /**
   * @author Mihail Petrov
   * @param $value
   */
  public processUtilityCollection($value: any) {

    this.utilityCollection  = $value?.map((element: any) => {
      return { name: element?.name, code: element?.name };
    });
  }

  /**
   * @author Mihail Petrov
   * @param $value
   */
  public processRatePlanCollection($value: any) {

    this.ratePlanCollection           = $value;
    this.utilityPlanGroupCollection   = Object.keys($value).map((element: any) => {
        return {name: element, value: element }
    });
  }

  /**
   * @author Mihail Petrov
   * @param value
   */
  public processutilityPlanGroupSelection(value: any) {

    this.utilityPlanCollection = Object.keys(this.ratePlanCollection[value]).map((planElement: any) => {
      return { name : planElement, value : planElement  }
    });
  }

  /**
   * @author Mihail Petrov
   * @param value
   */
  public processUtilityPlanSelection(utilityPlan: string) {
    const utilityGroup = this.$form.get('utilityPlanGroup')?.value;
    const plan = this.ratePlanCollection[utilityGroup][utilityPlan];

    this.state.utilityUrl = plan?.pageUrl;
    this.$form.get('utilityPlan')?.setValue(utilityPlan);
    this.$form.get('utilityPlanType')?.setValue(plan.ratePlanType);
    this.$form.get('fixedMonthlyCost')?.setValue(plan.fixedMonthlyCost);
    this.$form.get('utilityPlanId')?.setValue(plan.pageId);

    if (plan.ratePlanType === 'fixed') {
      // "fixed" approach
      this.calculateFixedPlanType(plan.rates);
    } else if (plan.ratePlanType === 'tou') {
      // "TOU" approach
      this.calculateTearedPlanType(plan.rates);
    }

    const prevDriverPrice = this.$form.get('driverPrice')?.value ?? 0;
    this.$form.get('driverPrice')?.setValue(prevDriverPrice);

    this.processDriverPriceMultiplierChange();

    this.onUtilityDetailsCalculated.emit();
    this.SiteService.sendData('select_utility_plan');
  }


  public processPlanUrl() {
    if(this.state.utilityUrl) window.open(this.state.utilityUrl, '_blank');
  }

  /**
   * @author Mihail Petrov
   */
  public processDriverPriceMultiplierChange() {
    const planType   = this.$form.get('utilityPlanType')?.value;
    const multiplier = this.$form.get('driverPriceMultiplier')?.value || 1;

    if (planType === 'fixed') {
      const electricityCost = this.$form.get('electricityCost')?.value ?? 0;
      const newDriverPrice  = parseFloat((electricityCost * multiplier).toFixed(2));
      this.$form.get('driverPrice')?.setValue(newDriverPrice);
      
      this.$form.get('driverPricePeak')?.setValue(null);
      this.$form.get('driverPriceOffPeak')?.setValue(null);
      this.$form.get('driverPriceSuperOffPeak')?.setValue(null);
    }
    else if (planType === 'tou') {
      const costPeak       = this.$form.get('electricityCostPeak')?.value ?? 0;
      const costOffPeak    = this.$form.get('electricityCostOffPeak')?.value ?? 0;
      const costSuperOff   = this.$form.get('electricityCostSuperOffPeak')?.value ?? 0;

      const newPeak        = parseFloat((costPeak      * multiplier).toFixed(2));
      const newOffPeak     = parseFloat((costOffPeak   * multiplier).toFixed(2));
      const newSuperOffPeak    = parseFloat((costSuperOff  * multiplier).toFixed(2));

      this.$form.get('driverPricePeak')?.setValue(newPeak);
      this.$form.get('driverPriceOffPeak')?.setValue(newOffPeak);
      this.$form.get('driverPriceSuperOffPeak')?.setValue(newSuperOffPeak);

      this.$form.get('driverPrice')?.setValue(newPeak);
    }

    this.onPricingDetailsCalculated.emit();
  }



  /**
   * @author Mihail Petrov
   * @param $value
   */
  public processHardwareConfigurationCollection($value: string) {

    if($value != null) {
      (this.$form.get('hardwareConfigCollection') as FormArray).clear()
    }

    for(const configElement of $value) {

      (this.$form.get('hardwareConfigCollection') as FormArray).push(new CcFormGroup({

        hardwareConfiguration           : new FormControl<string|null>  ((configElement as any)?.hardwareConfiguration as any , [Validators.required]),
        numberOfUnits                   : new FormControl<number|null>  ((configElement as any)?.numberOfUnits                , [Validators.required]),
        numberOfPorts                   : new FormControl<number|null>  ((configElement as any)?.numberOfPorts),
        hardwareCapacity                : new FormControl<number|null>  ((configElement as any)?.hardwareCapacity),
        hasNACScable                    : new FormControl<boolean>      ((configElement as any)?.hasNACScable)
      }));
    }
  }

  /**
   * @author Mihail Petrov
   * @param $value
   */
  public processHardwareConfiguration() {

    let hardwareCost      = 0;
    let installationCost  = 0;
    let numberOfPorts     = 0;


    // **
    for(const configElement of this.getHardwareCollection()) {

      const hardwareConfigurationValue  = configElement.get('hardwareConfiguration')?.value;
      const numberOfUnitsValue          = configElement.get('numberOfUnits')?.value;
      const hasNACScable                = configElement.get('hasNACScable')?.value;

      const hardwareCostConfiguration = this.$utilityCollectionService.get(hardwareConfigurationValue, {
        hasNACScable      : hasNACScable,
        isBabaApplicable  : this.$form.get('hasIncentivesBABA')?.value ? 'Y' : 'N',
        isNaviCamplient   : false
      });

      // Send to Back-end
      configElement.get('numberOfPorts'     )?.setValue(hardwareCostConfiguration?.numberOfPorts)
      configElement.get('hardwareCapacity'  )?.setValue(hardwareCostConfiguration?.capacity)


      if(hardwareConfigurationValue == null) {
        continue;
      }

      hardwareCost      += (numberOfUnitsValue! * hardwareCostConfiguration?.hardwareCost!)
      installationCost  += (numberOfUnitsValue! * hardwareCostConfiguration?.installationCost!)
      numberOfPorts     += (hardwareCostConfiguration?.numberOfPorts! * numberOfUnitsValue);
    }

    this.setHardwareCost(hardwareCost);
    this.setInstallationCost(installationCost);
    this.$form.get('numberOfPorts'    )?.setValue(numberOfPorts);
  }

  /**
   * @author Mihail Petrov
   * @param value
   */
  private setHardwareCost(value: number) {

    if(this.$form.get('isHardwareCostManuallyChanged')?.value) {
      return;
    }

    this.$form.get('hardwareCost'     )?.setValue(value);
  }

  /**
   * @author Mihail Petrov
   * @param value
   * @returns
   */
  private setInstallationCost(value: number) {

    if(this.$form.get('isInstallationCostManuallyChanged')?.value) {
      return;
    }

    this.$form.get('installationCost' )?.setValue(value);
  }


  /**
   * @author Mihail Petrov
   * @param rateCollection
   */
  private calculateFixedPlanType(rateCollection: any) {

    this.$form.get('electricityCost')?.setValue(rateCollection[0].rate)
    this.$form.get('utilityCost'    )?.setValue({
      "type"          : this.$form.get('utilityPlanType'            )?.value,
      "cost"          : this.$form.get('electricityCost'        )?.value,
    });
  }

  /**
   * @author Mihail Petrov
   * @param rateCollection
   */
  private calculateTearedPlanType(rateCollection: any) {
    let onPeak = 0, offPeak = 0, superOffPeak = 0;
    for (const rateElement of rateCollection) {
      if (rateElement?.tier === 'Super Off Peak') {
        superOffPeak = rateElement.rate;
        this.$form.get('electricityCostSuperOffPeak')?.setValue(superOffPeak);
      }
      if (rateElement?.tier === 'Off Peak') {
        offPeak = rateElement.rate;
        this.$form.get('electricityCostOffPeak')?.setValue(offPeak);
      }
      if (rateElement?.tier === 'On Peak') {
        onPeak = rateElement.rate;
        this.$form.get('electricityCostPeak')?.setValue(onPeak);
      }
    }

    this.$form.get('electricityCost')?.setValue(onPeak);

    this.$form.get('utilityCost')?.setValue({
      type         : 'tou',
      peak         : onPeak,
      offPeak      : offPeak,
      superOffPeak : superOffPeak,
    });
  }

  public processBabaConfiguration($event: any) {
    this.processHardwareConfiguration();
  }
}
