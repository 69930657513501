import { CommonModule } from '@angular/common';
import { Component, inject, OnInit, ViewEncapsulation } from '@angular/core';
import { LoaderService } from 'nv@services/loader.service';
import { DialogModule } from 'primeng/dialog';

@Component({
  standalone    : true,
  selector      : 'app-loader-spinner',
  templateUrl   : './app-loader-spinner.component.html',
  styleUrl      : './app-loader-spinner.component.scss',
  imports       : [CommonModule, DialogModule]
})
export class AppLoaderSpinnerComponent implements OnInit {

  public loader: LoaderService = inject(LoaderService);

  public isVisible = false;

  public ngOnInit(): void {

    this.loader.hasErrors$.subscribe((response: boolean) => {
      this.isVisible = response;
    });
  }

}
