import {  Component                   ,
          inject                      ,
          OnInit                      } from '@angular/core';
import {  CommonModule                } from '@angular/common';
import {  RouterOutlet                } from '@angular/router';
import {  DialogService               ,
          DynamicDialogModule         } from 'primeng/dynamicdialog';
import {  DialogModule                } from 'primeng/dialog';

import {  AppLoaderSpinnerComponent   } from 'nv@components/app-loader-spinner/app-loader-spinner.component';
import {  TranslateService            } from 'nv@services/translate.service';
import {  GlobalRedirectService       } from './@routes/redirect.service';
import {  MsalService                 } from '@azure/msal-angular';
import { SiteApi } from 'nv@api/site.api.service';
import { interval, Subscription, switchMap } from 'rxjs';

@Component({
  selector    : 'app-root',
  templateUrl : './app.component.html',
  styleUrls   : ['./app.component.scss'],
  providers   : [DialogService],
  imports     : [
    CommonModule              ,
    RouterOutlet              ,
    AppLoaderSpinnerComponent ,
    DialogModule              ,
    DynamicDialogModule       ,
  ],
})
export class AppComponent implements OnInit {

  private globalRedirect    = inject(GlobalRedirectService);
  private translateService  = inject(TranslateService);
  private $auth             = inject(MsalService);
  private siteApi = inject(SiteApi);

  public openEiStatus: boolean | null = null;
  private statusSubscription!: Subscription;
  public isFetchingStatus: boolean = false;
  
  public username           = this.$auth.instance?.getActiveAccount()?.name;
  public userSignature      = "";
  public isUsernameVisible  = true;

  public ngOnInit(): void {

    this.translateService.loadTranslations('en');

    const username              = this.$auth.instance?.getActiveAccount()?.name;
    this.isUsernameVisible      = !!username;

    const usernameCollection    = username?.split(',');
    const firstSignature        = usernameCollection ? usernameCollection[1]?.trim() : '';
    const secondSignature       = usernameCollection ? usernameCollection[0]?.trim() : '';
    this.userSignature          = `${firstSignature[0]}${secondSignature[0]}`;
    this.username               = `${firstSignature} ${secondSignature}`;

    // Call OpenEI status API every 5 minutes
    this.fetchOpenEiStatus();
    this.statusSubscription = interval(5 * 60 * 1000) // 5 minutes
      .pipe(switchMap(() => {
        this.isFetchingStatus = true;
        return this.siteApi.getOpenEiStatus();
      }))
      .subscribe(response => {
        this.openEiStatus = response.data;
        this.isFetchingStatus = false;
      }, error => {
        console.error("Failed to fetch OpenEI status:", error);
        this.openEiStatus = null;
        this.isFetchingStatus = false;
      });
  }

  public goHome() {
    this.globalRedirect.inContextOfCustomer().viewAllCustomers();
  }

  private fetchOpenEiStatus(): void {
    this.isFetchingStatus = true;
    this.siteApi.getOpenEiStatus().subscribe(response => {
      this.openEiStatus = response.data;
      this.isFetchingStatus = false;
    }, error => {
      console.error("Failed to fetch OpenEI status:", error);
      this.openEiStatus = null;
      this.isFetchingStatus = false;
    });
  }

  public getStatusClass(): string {
    if (this.openEiStatus === true) {
      return 'status-green';
    } else if (this.openEiStatus === false) {
      return 'status-red';
    } else {
      return 'status-yellow'; // If null or undefined
    }
  }

  public getStatusLabel(): string {
    if (this.openEiStatus === true) {
      return "Active";
    } else if (this.openEiStatus === false) {
      return "Inactive";
    } else {
      return "Checking...";
    }
  }

}
