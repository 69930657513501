import { CommonModule         } from "@angular/common";
import { Component            } from "@angular/core";
import { ReactiveFormsModule  } from "@angular/forms";
import { SidebarModule        } from 'primeng/sidebar';
import { DropdownModule       } from "primeng/dropdown";

import { CcFormError          } from "nv@components/cc-form-error/cc-form-error.component";
import { CcBaseSidebar        } from "nv@components/cc-sidebar/cc-sidebar.base";
import { CcSidebar            } from "nv@components/cc-sidebar/cc-sidebar.component";
import { TranslatePipe        } from "nv@services/translate.service";

@Component({
  standalone  : true,
  selector    : 'sidebar-manage-sites',
  templateUrl : './manage-sites.component.html',
  styleUrl    : './manage-sites.component.scss',
  imports     : [
    ReactiveFormsModule ,
    CommonModule        ,
    SidebarModule       ,
    SidebarModule       ,
    CcSidebar           ,
    CcFormError         ,
    DropdownModule      ,
    TranslatePipe
  ]
})
export class SidebarManageSites extends CcBaseSidebar {

  public sidebarTitle  = 'Remove site';

  /**
   * @author Mihail Petrov
   */
  public removeEntity() {
    this.returnToParent(this.inputData);
  }
}
