import {  Component     ,
          EventEmitter  ,
          Input         ,
          Output        } from "@angular/core";
import {  CommonModule  } from "@angular/common";
import {  ContactModel  } from "nv@models/contact.model";
import {  CcMap         } from "nv@components/cc-map/cc-map.component";
import {  TranslatePipe } from "nv@services/translate.service";

@Component({
  standalone  : true,
  selector    : 'template-project-map',
  templateUrl : './template-project-map.component.html',
  imports     : [
    CommonModule,
    CcMap,
    TranslatePipe
  ]
})
export class TemplateProjectMap {

  @Input() public inputData!: any;
  @Output() public onAdd = new EventEmitter();
  @Output() public onAssigneContact = new EventEmitter();

  @Input() public inputContactCollection!: ContactModel[];
  @Input() public inputMarkerCollection: any;

}
