import { Component, ViewChild, ElementRef, AfterViewInit, Input, OnChanges } from '@angular/core';
import { Map, AuthenticationType } from 'azure-maps-control';
import * as atlas from "azure-maps-control"


function calculateCenter(markers: any) {

  const total = markers.length;
  let latSum = 0;
  let lngSum = 0;

  markers.forEach((marker: any) => {
      latSum += marker.latitude;
      lngSum += marker.longitude;
  });

  return {
      latitude: latSum / total,
      longitude: lngSum / total
  };
}

function calculateBounds(markers: any) {

  const bounds = {
      southwest: { lat: Infinity, lng: Infinity },
      northeast: { lat: -Infinity, lng: -Infinity }
  };

  markers.forEach((marker: any) => {

      bounds.southwest.lat = Math.min(bounds.southwest.lat, marker.latitude);
      bounds.southwest.lng = Math.min(bounds.southwest.lng, marker.longitude);
      bounds.northeast.lat = Math.max(bounds.northeast.lat, marker.latitude);
      bounds.northeast.lng = Math.max(bounds.northeast.lng, marker.longitude);
  });

  return bounds;
}


@Component({
  standalone  : true,
  selector    : 'cc-map',
  templateUrl : './cc-map.component.html',
  styleUrl    : './cc-map.component.scss',
})
export class CcMap implements OnChanges {

    @Input() public inputType?: string = 'road';
    @Input() public inputLong?: number;
    @Input() public inputLat?: number;
    @Input() public markerLocations: { latitude : number, longitude: number }[] = [];

    @ViewChild('map', { static: true })
    public mapContainer!: ElementRef;


    public mapReference!: atlas.Map;


    public ngOnChanges() {

      const collection              = this.buildMarkerLocation();
      const {latitude, longitude}   = calculateCenter(collection);
      const bounds                  = calculateBounds(collection);

      const boundingBox = new atlas.data.BoundingBox([
        bounds.southwest.lng,
        bounds.southwest.lat,
        bounds.northeast.lng,
        bounds.northeast.lat
      ]);

      const htmlMarkerCollection    = this.buildMarkerCollection(collection);

      this.mapReference = new atlas.Map(this.mapContainer.nativeElement, {
        center                : [longitude, latitude],
        zoom                  : (this.inputType == 'landmark') ? 18 : 12,
        maxZoom               : 18,
        style                 : (this.inputType == 'landmark') ? 'satellite_road_labels' : this.inputType             ,
        interactive           : true,
        scrollZoomInteraction : true,
        dragPanInteraction    : true,
        authOptions: {
            authType        : AuthenticationType.subscriptionKey,
            subscriptionKey : "G2HrrpHBk1oFvAnB39DFtULTJbD8XDwkJyWWUXRhvuROvbTo6JUvJQQJ99AJACYeBjFZ6F0yAAAgAZMP2OnW"
        }
      });

      if(htmlMarkerCollection && htmlMarkerCollection.length > 1) {

        this.mapReference.setCamera({
          padding : 50,
          bounds  : boundingBox
        });
      }

      this.mapReference.markers.add(htmlMarkerCollection);
    }


    /**
     * @author Mihail Petrov
     * @returns
     */
    public buildMarkerLocation() {

      if(this.inputLat && this.inputLong) {
        return [{ latitude: this.inputLat, longitude: this.inputLong }];
      }

      return this.markerLocations;
    }

    /**
     * @author Mihail Petrov
     * @param collection
     * @returns
     */
    public buildMarkerCollection(collection: any[]) {

      const markerCollection: any = [];

      collection.forEach(location => {

        const marker = new atlas.HtmlMarker({
          position  : [location?.longitude, location?.latitude],
          popup     : new atlas.Popup({
            content : `<div>${location.latitude}, ${location.longitude}</div>`
          })
        });

        markerCollection.push(marker);
      });

      return markerCollection;
    }
}












// import { Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from "@angular/core";
// import { LeafletModule } from "@asymmetrik/ngx-leaflet";
// import * as Leaflet from 'leaflet';

// export const getLayers = (): Leaflet.Layer[] => {
//   return [
//     new Leaflet.TileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {} as Leaflet.TileLayerOptions),
//   ] as Leaflet.Layer[];
// };

// @Component({
//   standalone: true,
//   selector: 'cc-map',
//   templateUrl: './cc-map.component.html',
//   styleUrl: './cc-map.component.scss',
//   imports: [
//     LeafletModule
//   ]
// })
// export class CcMap implements OnChanges {

//   @Input() public inputLong?: number;
//   @Input() public inputLat?: number;
//   @Input() public markerLocations: { latitude : number, longitude: number }[] = [];

//   @ViewChild('map', { static: true }) mapElement!: ElementRef;

//   public map!: Leaflet.Map;

//   public options: Leaflet.MapOptions = {
//     layers  : getLayers(),
//     zoom    : 12,
//     center  : new Leaflet.LatLng(0, 0)
//   };

//   public ngAfterViewInit(): void {
//     this.initializeMap();
//   }

//   public ngOnChanges(changes: SimpleChanges): void {

//     if (this.map) {

//       console.log("DDDDDDDDD");

//       if (changes['markerLocations'] && this.markerLocations?.length > 0) {
//         this.addMarkersAndFitBounds(this.markerLocations);  // Handle multiple markers if available
//       } else if (changes['inputLat'] && changes['inputLong'] && this.inputLat !== undefined && this.inputLong !== undefined) {
//         // If inputLat and inputLong are present, use them as a single marker location
//         this.addMarkersAndFitBounds([{ latitude: this.inputLat, longitude: this.inputLong }]);  // Single marker logic
//       }
//     }
//   }

//   /**
//    * @author Mihail Petrov
//    */
//   private initializeMap(): void {

//     if (!this.map) {

//       this.map = Leaflet.map(this.mapElement.nativeElement, this.options);

//       if (this.markerLocations?.length > 0) {
//         this.addMarkersAndFitBounds(this.markerLocations);
//       } else if (this.inputLat !== undefined && this.inputLong !== undefined) {
//         this.addMarkersAndFitBounds([{ latitude: this.inputLat, longitude: this.inputLong }]);
//       }
//     }
//   }

//   // Add markers and fit map bounds whether single or multiple markers
//   private addMarkersAndFitBounds(locations: { latitude: number, longitude: number }[]): void {
//     if (this.map && locations.length > 0) {
//       // Clear existing markers
//       this.map.eachLayer(layer => {
//         if (layer instanceof Leaflet.Marker) {
//           this.map.removeLayer(layer);
//         }
//       });

//       // Add new markers and adjust the bounds
//       const bounds = Leaflet.latLngBounds([]);
//       locations.forEach(location => {
//         const marker = Leaflet.marker([location.latitude, location.longitude]);
//         marker.addTo(this.map);
//         bounds.extend(marker.getLatLng());
//       });

//       // Fit the map view to the bounds of the markers
//       this.map.fitBounds(bounds);
//     }
//   }
// }

