<cc-sidebar
  [(inputIsVisible)]  = "inputIsVisible"
  (onHide)            = "processHideSidebar()"
  [inputTitle]        = "sidebarTitle">

  @if(isInDeleteMode())
  {
    <div class="panel panel-warning">You are about to remove <strong>{{this.inputData.name}}</strong> from the project. This will reflect in the overall score point of the project. The operation is irreversible - proceed with care.</div>

    <div style="display: flex; margin-top: 16px; justify-content: flex-end;">
      <div (click)="processHideSidebar()" class="btn mr16">
        <i class="pi pi-times"></i>
        {{'@@MANAGE_SITES.ACTION.CANCEL' | translate }}
      </div>
      <div (click)="removeEntity()" class="btn btn-danger">
        <i class="pi pi-trash"></i>
        {{'@@MANAGE_SITES.ACTION.REMOVE' | translate }}
      </div>
    </div>
  }

</cc-sidebar>
