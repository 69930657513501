import { Injectable } from "@angular/core";
import GeoState       from 'assets/collections/geo.states.json';
import GeoStateCity   from 'assets/collections/geo.state-name-city.json';
import GeoZip         from 'assets/collections/geo.zip-to-zea.json';


@Injectable({providedIn: 'root'})
export class StateCollectionService {

  /**
   *
   * @returns
   */
  public getAllStates() {
    return GeoState as any;
  }

  /**
  * @author Mihail Petrov
   * @param stateName
   * @returns
   */
  public getAllCityByStateName(stateName: string | null | undefined) {

    if(stateName) {

      const collection: any = (GeoStateCity as any)[stateName]

      return collection.map((element: string) => {
        return { name: element }
      });
    }

    return []
  }

  /**
   * @author Mihail Petrov
   * @param stateName
   * @returns
   */
  public getAllCityByStateId(stateId: string | null | undefined) {

    if(stateId) {

      const collection: any = (GeoStateCity as any)[stateId]
      const r = collection.map((element: string) => {
        return { name: element }
      });

      return r;

    }

    return []
  }

  /**
   * @author Mihail Petrov
   * @param zip
   * @returns
   */
  public getZctaFromZip(zip: any) {

    const zcta = GeoZip[zip];
    return zcta ?? zip;
  }

}
