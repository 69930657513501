import { Component, Input } from "@angular/core";

@Component({
  standalone  : true,
  selector    : 'cc-project-label',
  templateUrl : './cc-project-label.component.html',
  styleUrl    : './cc-project-label.component.scss'
})
export class CcProjectLabel {

  @Input() inputStatus!: any;

}
