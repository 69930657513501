import { FormArray, FormControl, FormGroup } from "@angular/forms";

export class CcFormGroup extends FormGroup {

  /**
   * @author Mihail Petrov
   * @param formGroup
   * @returns
   */
  public markFormGroupDirty(formGroup: FormGroup | FormArray = this) {

    Object.keys(formGroup.controls).forEach(field => {

      const control = formGroup.get(field);

      if (control instanceof FormControl) {
        control.markAsDirty({ onlySelf: true });
        control.updateValueAndValidity();

      }
      else if (control instanceof FormGroup) {
        this.markFormGroupDirty(control);

      }
      else if (control instanceof FormArray) {

        control.controls.forEach((arrayControl) => {

          if (arrayControl instanceof FormGroup || arrayControl instanceof FormArray) {
            this.markFormGroupDirty(arrayControl);
          }
          else if (arrayControl instanceof FormControl) {

            arrayControl.markAsDirty({ onlySelf: true });
            arrayControl.updateValueAndValidity();
          }
        });
      }
    });

    return this
  }


  /**
   * @author Mihail Petrov
   * @param callback
   */
  public isFormValid(callback: any) {

    if(this.valid) {
      callback();
    }
  }

  /**
   * @author Mihail Petrov
   * @param form
   * @param field
   * @returns
   */
  public isValid(form: FormGroup, field: string) {
    return form.get(field)?.invalid && (form.get(field)?.dirty || form.get(field)?.touched)
  }

  public asArray(field: string): CcFormArray {
    return this.get(field) as CcFormArray;
  }
}

export class CcFormArray extends FormArray {

  public markArrayAsDirty() {

    for(let element of this.controls) {
      (element as CcFormGroup).markFormGroupDirty();
    }

    return this;
  }

  /**
   * @author Mihail Petrov
   * @param callback
   */
  public isFormValid(callback: any) {

    if(this.valid) {
      callback();
    }
  }

  /**
   * @author Mihail Petrov
   * @param form
   * @param field
   * @returns
   */
  public isValid(form: FormGroup, field: string) {
    return form.get(field)?.invalid && (form.get(field)?.dirty || form.get(field)?.touched)
  }
}


