import {  CommonModule  } from '@angular/common';
import {  Component     ,
          Input         } from '@angular/core';
import {  TranslatePipe } from 'nv@services/translate.service';
import { CcMap } from "../../../../@shared/components/cc-map/cc-map.component";

@Component({
    selector    : 'template-competition',
    templateUrl: './template-competition.component.html',
    imports: [CommonModule, TranslatePipe, CcMap]
})
export class TemplateCompetition {

    @Input() public inputData: any;
    @Input() public inputIsVisible = false

    public getCompetitionMarkers() {
        return this.inputData.competition?.latLongOfAllChargers
    }
}