import {  Component                     ,
          Input                         } from "@angular/core";
import {  CommonModule                  } from "@angular/common";
import {  TranslatePipe                 } from "nv@services/translate.service";
import { TransfromToYearMonthLabelPipe  } from "nv@pipes/year-month-label.pipe";

@Component({
  selector    : 'template-bottom-summary',
  templateUrl : './template-bottom-summary.component.html',
  styleUrl    : './template-bottom-summary.component.scss',
  imports: [
    CommonModule,
    TranslatePipe,
    TransfromToYearMonthLabelPipe
]
})
export class TemplateBottomSummary {

  @Input() public inputData!: any;

  public getScoreData(): any {
        // Grab each field or fallback to 0
        const market     = this.inputData?.marketPotentialScore ?? 0;
        const infra      = this.inputData?.chargingInfrastructureScore ?? 0;
        const financial  = this.inputData?.financialUtilizationPerformanceScore ?? 0;
        const nevi       = this.inputData?.neviScore ?? 0;

        // return [
        //     { title: 'Market',         value: market     },
        //     { title: 'Infrastructure', value: infra      },
        //     { title: 'Financial',      value: financial  },
        //     { title: 'NEVI',           value: nevi       }
        // ];

        return [
            { title: 'Market',         value: market     },
            { title: 'Infrastructure', value: infra      },
            { title: 'Financial',      value: financial + nevi  }
        ];
    }
    
    public getMaxValue(index: number): number {
        // const maxValues = [25, 20, 45, 10];
        const maxValues = [25, 20, 55];
        return maxValues[index] ?? 100;
    }

    /**
    * @author Samuil Dobrinski
    * @returns
    */
    public getBarColor(index: number): string {
        const colors = ["#C6213E", "#6D9953", "#22689A", "#D3F26A"];
        return colors[index] || "#CCCCCC"; // Default color if index is out of range
    }
}
