import { HttpEvent        ,
         HttpHandler      ,
         HttpInterceptor  ,
         HttpRequest      } from '@angular/common/http';
import { inject           ,
         Injectable       } from '@angular/core';
import { MsalService      } from '@azure/msal-angular';
import { Observable       ,
         tap              } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private authService: MsalService = inject(MsalService);

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    req = req.clone({
      setHeaders: {
        'Authorization': `Bearer ${this.authService.instance.getActiveAccount()?.idToken}`,
      },
    });

    return next.handle(req);
  }
}


@Injectable()
export class ForbiddenInterceptor implements HttpInterceptor {

  private authService: MsalService = inject(MsalService);

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(tap(x => {
      // console.log(x.type == HttpEventType.Response)
      // console.log(x)
    }))
  }
}
