@if(inputIsVisible)
{
  <div class="card-box">

    <div>
      <i class="pi pi-truck fs32 m5"></i>
    </div>

    <div class="ml16">
      <div class="fsb18">     {{ inputData?.utility     }} </div>
      <div class="just-flex"> {{ inputData?.utilityPlan }} </div>
    </div>
  </div>
}
@else
{
  <div class="cc-placeholder">
    <div>
      <i class="pi pi-truck fs32 m5"></i>
    </div>
    <div class="ml16 fs24"> {{'@@SITE.TEMPLATE.UTILITY_SERVICE.NAME' | translate}} </div>
  </div>
}
