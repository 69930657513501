import { Injectable } from "@angular/core";

@Injectable({providedIn: 'root'})
export class UtilityCollectionService {

  /**
   * @author Mihail Petrov
   * @returns
   */
  public getHardwareConfiguration() {

    return [
      { name: 'All in One 200 kW Charger'  , numberOfPorts: 2, hardwareCost: 116250, installationCost: 116250 },
      { name: 'Distributed Solution - 1 Charger  '  , numberOfPorts: 2, hardwareCost: 281250, installationCost: 281250 },
      { name: 'Distributed Solution - 2 Chargers '  , numberOfPorts: 4, hardwareCost: 351563, installationCost: 351563 },
      { name: 'Distributed Solution - 4 Chargers '  , numberOfPorts: 8, hardwareCost: 483750, installationCost: 483750 }
    ];
  }
}
