import {  Injectable                  ,
          inject                      } from "@angular/core";
import {  ActivatedRoute              ,
          NavigationEnd               ,
          NavigationStart             ,
          Router                      } from "@angular/router";
import {  ApplicationRedirectContext  ,
          CustomerRedirectContext     } from "./@redirect-context/application-redirect.context";

  @Injectable({
    providedIn: "root"
  })
  export class GlobalRedirectService {

    protected router: Router              = inject(Router);
    protected activeRoute: ActivatedRoute = inject(ActivatedRoute);

    /**
     * @author Mihail Petrov
     * @param callback
     */
    public onNavigationStart(callback: any): void {

      this.router.events.subscribe(event => {

        if(event instanceof NavigationStart) {
          callback(event);
        }
      });
    }

    /**
     * @author Mihail Petrov
     * @param callback
     */
    public onNavigationEnd(callback: any): void {

      this.router.events.subscribe(event => {

        if(event instanceof NavigationEnd) {
          callback(event);
        }
      });
    }

    /**
     * @author Mihail Petrov
     * @returns
     */
    public inContextOfApplication(reloadPage: boolean = true) {
      return new ApplicationRedirectContext(this.router, reloadPage);
    }

    /**
     * @author Mihail Petrov
     * @returns
     */
    public inContextOfCustomer() {
      return new CustomerRedirectContext(this.router);
    }
  }
