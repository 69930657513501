<cc-sidebar
  [(inputIsVisible)]  = "inputIsVisible"
  (onHide)            = "processHideSidebar()"
  [inputTitle]        = "sidebarTitle">

  @if(isInCreateEditMode())
  {
    <form [formGroup]="$form" style="height: 100%;">
      <div style="display: flex; flex-direction: column;justify-content: space-between; height: 100%;">

      <div>
        <div class="mt16">
          <label>{{'@@MANAGE_CONTACTS.PAGE.FIRST_NAME' | translate }} * </label>
          <input class                = "input"
                  [class.is-invalid]  = "$form.isValid($form, 'firstName')"
                  type                = "text"
                  formControlName     = "firstName"
                  placeholder         = "{{'@@MANAGE_CONTACTS.PAGE.FIRST_NAME' | translate }}"/>
                <cc-form-error [inputForm]="$form" [inputField]="'firstName'"></cc-form-error>
        </div>

        <div class="mt16">
          <label>{{'@@MANAGE_CONTACTS.PAGE.LAST_NAME' | translate }} *</label>
          <input class              = "input"
                [class.is-invalid]  = "$form.isValid($form, 'lastName')"
                type                = "text"
                formControlName     = "lastName"
                placeholder         = "{{'@@MANAGE_CONTACTS.PAGE.LAST_NAME' | translate }}"/>
                <cc-form-error [inputForm]="$form" [inputField]="'lastName'"></cc-form-error>
        </div>

        <div class="mt16">
          <label>{{'@@MANAGE_CONTACTS.PAGE.ROLE' | translate }} *</label>
          <input class              = "input"
                [class.is-invalid]  = "$form.isValid($form, 'role')"
                type                = "text"
                formControlName     = "role"
                placeholder         = "{{'@@MANAGE_CONTACTS.PAGE.ROLE' | translate }}"/>
                <cc-form-error [inputForm]="$form" [inputField]="'role'"></cc-form-error>
        </div>

        <div class="mt16">
          <label>{{'@@MANAGE_CONTACTS.PAGE.PHONE' | translate }} *</label>

          <div class="flex">

            <p-dropdown
              formControlName = "phoneCc"
              [filter]        = "true"
              [options]       = "$countryCollectionService.getAllCountryPhoneCodes()"
              class           = "mr16"
              filterBy        = "name"
              optionLabel     = "name"
              optionValue     = "dial_code"
              placeholder     = "Code">

                <ng-template pTemplate="selectedItem">
                  <div>{{$form.get('phoneCc')?.value}}</div>
                </ng-template>

                <ng-template let-country pTemplate="item">
                  <div class="just-flex">
                      <img
                          src   = "assets/images/country_flags/{{country.code}}.svg"
                          class = "mr16"
                          style = "width: 18px" />
                      <div>{{ country.name }}</div>
                  </div>
                </ng-template>
              </p-dropdown>

            <input onlyNumber
                  class              = "input"
                  [class.is-invalid]  = "$form.isValid($form, 'phone')"
                  type                = "tel"
                  formControlName     = "phone"
                  placeholder         = "Number"/>
          </div>
                <cc-form-error [inputForm]="$form" [inputField]="'phone'"></cc-form-error>
                <cc-form-error [inputForm]="$form" [inputField]="'cc'"></cc-form-error>
        </div>

        <div class="mt16">
          <label>{{'@@MANAGE_CONTACTS.PAGE.EMAIL' | translate }} *</label>
          <input class              = "input"
                [class.is-invalid]  = "$form.isValid($form, 'email')"
                type                = "email"
                [email]             = "true"
                formControlName     = "email"
                placeholder         = "{{'@@MANAGE_CONTACTS.PAGE.EMAIL' | translate }}"/>
                <cc-form-error [inputForm]="$form" [inputField]="'email'" [inputValidatorType]="'email'"></cc-form-error>
        </div>
      </div>

      <div class="mt16">
      @if(isInCreateMode())
      {
        <div (click)="processForm()" class="btn--wide btn-primary">
          <i class="pi pi-plus mr16"></i> {{'@@MANAGE_CONTACTS.ACTION.CREATE' | translate }}
        </div>
      }

      @if(isInEditMode())
      {
        <div (click)="processForm()" class="btn--wide btn-primary">
          <i class="pi pi-pencil mr16"></i> {{'@@MANAGE_CONTACTS.ACTION.EDIT' | translate }}
        </div>
      }
      </div>

      </div>
    </form>
  }

  @if(isInDeleteMode())
  {
    <template-delete
      [inputData] = "inputData"
      (onCancel)  = "processHideSidebar()"
      (onDelete)  = "removeEntity()">
    </template-delete>
  }

  @if(isInImportMode())
  {
    <template-import
      [$form]     = "$form"
      (onUpload)  = "processFile($event)">
    </template-import>
  }

</cc-sidebar>
