import { Injectable } from "@angular/core"
import { AbstractControl, FormArray, FormGroup } from "@angular/forms"
import { CcFormGroup } from "../forms/form-group/cc-form-group.extention";

@Injectable({
  providedIn: 'root'
})
export class FormUtil {

  /**
   * @author Mihail Petrov
   * @param form
   * @param field
   * @returns
   */
  public isValid(form: FormGroup | CcFormGroup | AbstractControl, field: string) {
    return form.get(field)?.invalid && (form.get(field)?.dirty || form.get(field)?.touched)
  }

  /**
   * @author Mihail Petrov
   * @param form
   * @param dataObject
   */
  public trackAndUpdateControls(form: FormGroup | FormArray, dataObject: any): void {

    if (form instanceof FormGroup) {

      Object.keys(form.controls).forEach(controlName => {
        const control = form.get(controlName);

        if (control instanceof FormGroup || control instanceof FormArray) {
          this.trackAndUpdateControls(control, dataObject);
        }
        else if (dataObject.hasOwnProperty(controlName)) {
          control?.setValue(dataObject[controlName]);
        }
      });
    }

    if (form instanceof FormArray) {

      form.controls.forEach(control => {

        if (control instanceof FormGroup || control instanceof FormArray) {
          this.trackAndUpdateControls(control, dataObject);
        }
      });
    }
  }
}
