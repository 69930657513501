@if(!isFetchingStatus)
{
  <app-loader-spinner></app-loader-spinner>
}


<div class="web-toolbar">

  <div class="ml16">
    <img (click)="goHome()" class="web-logo" src="assets/images/logo_white.png">
  </div>

  <div class="status-container">
    <div class="status-pill">
      <span class="status-text">OpenEI Status:</span>
      <span class="status-circle" [ngClass]="getStatusClass()"></span>
      <!-- <span class="status-label">{{ getStatusLabel() }}</span> -->
    </div>
  </div>

  @if(isUsernameVisible)
  {
    <div class="username mr32 flex">
      <div class="mr16"
          style="border-radius: 16px; color: rgb(55, 65, 81); background: #fff; width: 34px; height: 34px; text-align: center; display: flex; justify-content: center; align-items: center;">{{userSignature}}</div>
      <div style="margin-top: 6px;">{{username}}</div>
    </div>
  }
</div>

<div class="web-content">
  <router-outlet></router-outlet>
</div>
