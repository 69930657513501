<cc-sidebar
  [(inputIsVisible)]  = "inputIsVisible"
  (onHide)            = "processHideSidebar()"
  [inputTitle]        = "sidebarTitle">

  @if(isInCreateEditMode())
  {
    <form style="height: 100%;">

      <div style="display: flex; flex-direction: column;justify-content: space-between; height: 100%;">

        <div>

          @if(projectContactList && projectContactList.length > 0)
          {
            <div>
              <div>{{'@@MANAGE_PROJECTS.PAGE.CONTACT_LIST' | translate }}</div>
              @for(contact of projectContactList; track contact.id)
              {
                <div
                  style   = "border-bottom: solid #dedede 1px;"
                  class   = "contact-element mt8 pb8 pl8 pt8 flex"
                  (click) = "addContact(contact)">
                  <div>
                    <div style="font-weight: bold;">{{contact.firstName}} {{contact.lastName}}</div>
                    <div>{{contact.role}}</div>
                  </div>
                  <div>

                    @if(contact.isSelected)
                    {
                      <div [ngClass]  = "{'is-primary': contact.isPrimary == true}"
                           style      = "font-size: 12px;"
                           (click)    = "makeItPrimary(contact)"
                           class      = "btn btn-plane mr8">
                        Primary contact
                      </div>

                      <div style="font-size: 12px;" (click)="removeContactFromProject(contact)" class="btn btn-danger mr8">
                        {{'@@MANAGE_PROJECTS.ACTION.REMOVE' | translate }}
                      </div>
                    }
                    @else
                    {

                      <div style="font-size: 12px;" (click)="addContactToProject(contact)" class="btn btn-plane mr8">
                        <!-- <i class="pi pi-plus mr8"></i>  -->
                        {{'@@MANAGE_PROJECTS.ACTION.ADD_TO_PROJECT' | translate }}
                      </div>
                    }
                  </div>
                </div>
              }
            </div>
          }
          @else
          {
            <div class="panel">{{'@@MANAGE_PROJECTS.PAGE.NO_CONTACT_FOR_PROJECT' | translate }}</div>
          }
        </div>

        <div class="mt16">
          @if(isInCreateMode())
          {
            <div (click)="processForm()" class="btn--wide btn-primary">
              <i class="pi pi-plus mr16"></i> {{'@@MANAGE_PROJECTS.ACTION.CREATE' | translate }}
            </div>
          }

          @if(isInEditMode())
          {
            <div (click)="processForm()" class="btn--wide btn-primary">
              <i class="pi pi-pencil mr16"></i> {{'@@MANAGE_PROJECTS.ACTION.EDIT' | translate }}
            </div>
          }
        </div>
      </div>

    </form>
  }

  @if(isInDeleteMode())
  {
    <div class="panel">Are you sure you want to delete <span style="font-weight: bold;">{{inputData.name}}</span>? <br>This action cannot be undone. All data associated with this project will be permanently removed.</div>

    <div class="flex mt16" style="justify-content: flex-end;">
      <div (click)="processHideSidebar()" class="btn mr16">
        <i class="pi pi-times"></i> Cancel
      </div>
      <div (click)="removeEntity()" class="btn btn-danger">
        <i class="pi pi-trash"></i> Delete project
      </div>
    </div>
  }

</cc-sidebar>
