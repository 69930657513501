import { Directive, HostListener, ElementRef, Input, OnInit, Inject } from '@angular/core';
import { formatCurrency } from '@angular/common';
import { NgControl } from '@angular/forms';
import { LOCALE_ID } from '@angular/core';

@Directive({
  selector: '[formatCurrency]',
})
export class FormatDirective implements OnInit {

  public format = 'N0';
  public digitsInfo = '1.0-0';

  @Input() currency = '$';
  @Input() sufix = '';
  @Input() decimalCharacter = null;

  @Input('format') set _(value: string) {
    this.format = value;
    if (this.format == 'N2') this.digitsInfo = '1.2-2';

    const parts = value.split(':');
    if (parts.length > 1) {
      this.format = parts[0];
      this.digitsInfo = parts[1];
    }
  }

  @HostListener('blur', ['$event.target'])
  public blur(target: any) {
    const numericValue = this.extractNumericValue(target.value);
    target.value = this.formatCurrencyValue(numericValue);
  }

  @HostListener('focus', ['$event.target'])
  public focus(target: any) {
    target.value = this.control.value;
  }

  @HostListener('input', ['$event.target'])
  public input(target: any) {
    const numericValue = this.extractNumericValue(target.value);

    // Update the control with the numeric value only
    if (this.control.control) {
      this.control.control.setValue(numericValue, { emitEvent: false });
    }
  }

  ngOnInit() {
    // Subscribe to the FormControl valueChanges to update the display when the value changes
    if (this.control && this.control.control) {
      this.control.control.valueChanges.subscribe((value: any) => {
        if (value != null && !isNaN(value)) {
          const formattedValue = this.formatCurrencyValue(value);
          this.el.nativeElement.value = formattedValue;
        }
      });
    }

    // Format the initial value if present
    setTimeout(() => {
      if (this.el.nativeElement.value) {
        const numericValue = this.extractNumericValue(this.el.nativeElement.value);
        this.el.nativeElement.value = this.formatCurrencyValue(numericValue);
      }
    });
  }

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private el: ElementRef,
    private control: NgControl
  ) {}

  /**
   * Extracts the numeric value from a string by removing non-numeric characters.
   */
  private extractNumericValue(value: string): number {
    const numericString = value.replace(/[^0-9.]/g, ''); // Retain digits and decimal point
    return parseFloat(numericString) || 0; // Convert to number or fallback to 0
  }

  /**
   * Formats a numeric value into a currency string.
   */
  private formatCurrencyValue(value: number): string {
    return formatCurrency(value, this.locale, this.currency, 'USD', this.digitsInfo);
  }
}
