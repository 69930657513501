@if (inputIsVisible)
{
    <div class="card-box" style="padding: 16px;">

        <div>
            <i class="pi pi-car fs32 m5"></i>
        </div>

        <div class="ml16 mt8">
            <!-- Title Row -->
            <div class="just-flex" style="align-items: center;">
                <span class="ml8" style="font-size: 18px; font-weight: 600;">
                    Score for this location
                </span>
            </div>

            <!-- <div class="tooltip-popup active" [ngStyle]="tooltipPosition">
                @for (score of tooltipData; track $index) {
                    <div class="tooltip-bar">
                    <div class="tooltip-text-container">
                        <div class="tooltip-title">{{ score.title }}</div>
                    </div>
                    <div class="progress-bar">
                        <div class="progress-fill"
                            [style.width.%]="(score.value / getMaxValue($index)) * 100"
                            [ngStyle]="{'background-color': getBarColor($index)}">
                        </div>
                    </div>
                    </div>
                }
            </div> -->


            <div class="ml16 mt8">
                <!-- Expected weekly visitors -->
                <div class="just-flex mt8" style="align-items: center;">
                    <div style="width: 200px;">Score</div>
                    <i style="font-size:12px;" class="pi pi-angle-double-right mt8 ml8 mr8"></i>
                    <div style="font-size:18px;">
                        {{ inputData.score }}
                    </div>
                </div>

                @for (distribution of getScoreData(); track $index){
                    <div class="just-flex mt8" style="align-items: center;">
                        <div style="width: 200px;">{{distribution.title}}</div>
                        <div class="progress-bar">
                            <div class="progress-fill"
                                [style.width.%]="(distribution.value / getMaxValue($index)) * 100"
                                [ngStyle]="{'background-color': getBarColor($index)}">
                            </div>
                        </div>
                    </div>
                }

            </div><!-- /margin-top -->
        </div>
    </div><!-- /card-box -->
}
@else
{
    <!-- If not visible, show placeholder -->
    <div class="cc-placeholder">
        <i class="pi pi-dollar fs32 m5"></i>
        <div class="ml16 fs24">
            Traffic for this location
        </div>
    </div>
}
