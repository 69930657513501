<form
  [formGroup]="$form">

  <template-form-header [inputIcon]="'microchip'"
    [inputTitle]="'@@SITE.WIZZARD.CONFIG.HARDWARE_CONFIGURATION'">
  </template-form-header>

  <div formArrayName ="hardwareConfigCollection">
  @for(element of getHardwareCollection(); track element; let index = $index, lastIndex = $last)
  {
    <div class="mt16 flex" [formGroupName]="index">

      <div class="col mr16">
        <label>{{'@@SITE.WIZZARD.CONFIG.HARDWARE_CONFIGURATION' | translate}} *</label>
        <p-dropdown
          class               = "ng-touched"
          autoWidth           = "false"
          [style]             = "{ width: '100%',  minWidth: '100%' }"
          formControlName     = "hardwareConfiguration"
          [class.is-invalid]  = "fu.isValid(element, 'hardwareConfiguration')"
          [options]           = "$utilityCollectionService.getHardwareConfigurationSetup()"
          optionLabel         = "name"
          optionValue         = "name"
          (onChange)          = "processHardwareConfiguration()"
          placeholder         = "{{'@@SITE.WIZZARD.CONFIG.HARDWARE_CONFIGURATION' | translate}}" />

          <cc-form-error [inputForm]="element" [inputField]="'hardwareConfiguration'"></cc-form-error>
      </div>

      <div class="col mr16">
        <label>{{'@@SITE.WIZZARD.CONFIG.NUMBER_OF_UNITS' | translate}} * </label>
        <input
          formControlName     = "numberOfUnits"
          [class.is-invalid]  = "fu.isValid(element, 'numberOfUnits')"
          (blur)              = "processHardwareConfiguration()"
          (change)            = "processHardwareConfiguration()"
          class               = "input"
          type                = "number"
          min                 = "1"
          max                 = "100"/>
          <cc-form-error [inputForm]="element" [inputField]="'numberOfUnits'"></cc-form-error>
      </div>

      <div class="col">

        <div class="just-flex mt36">

          <div style="margin-top: -8px;">
            <p-toggleButton
              (onChange)      = "processHardwareConfiguration()"
              class           = "ml8"
              formControlName = "hasNACScable"
              onLabel         = "with NACS"
              offLabel        = "no NACS"
              onIcon          = "pi pi-check"
              offIcon         = "pi pi-times"
              />


            <!-- <p-selectButton
              (onChange)      ="processHardwareConfiguration()"
              [options]       = "[ { label: 'no NACS', value: false }, { label: 'with NACS', value: true }]"
              formControlName = "hasNACScable"
              optionLabel     = "label"
              optionValue     = "value" /> -->
          </div>

          @if(getHardwareCollection().length > 1 )
          {
            <div (click)="removeHardwareConfig(index)"
              class       = "ml16"
              style="cursor: pointer;">
              <i style="color: #ff0000" class="pi pi-trash"></i>
            </div>
          }

          @if($last )
          {
            <button
              style="border: none; background: #fff; cursor: pointer;"
              [disabled]  = "!element.get('hardwareConfiguration')?.value"
              (click)     = "addNewHardwareConfig()"
              class       = "ml16">
              <i class    = "pi pi-plus-circle"></i>
            </button>
          }
        </div>

      </div>
    </div>
  }
  </div>


  <template-form-header [inputIcon]="'bolt'"
    [inputTitle]="'@@SITE.WIZZARD.CONFIG.UTILITY_PROVIDER_AND_PLAN'">
  </template-form-header>


  <div class="mt32 flex">

    <div class="col mr16">
      <label>{{'@@SITE.WIZZARD.CONFIG.UTILITY' | translate}} *</label>
      <p-dropdown
        formControlName     = "utility"
        [class.is-invalid]  = "fu.isValid($form, 'utility')"
        [options]           = "utilityCollection"
        optionLabel         = "name"
        optionValue         = "name"
        placeholder         = "{{'@@SITE.WIZZARD.CONFIG.UTILITY_PLACEHOLDER' | translate}}"
        (onChange)          = "processUtilityProviderSelection($event.value)"/>
    </div>

    <div class="col mr16">
      <label>{{'@@SITE.WIZZARD.CONFIG.UTILITY_GROUP' | translate}} *</label>
      <p-dropdown
        formControlName     = "utilityPlanGroup"
        [class.is-invalid]  = "fu.isValid($form, 'utilityPlanGroup')"
        [options]           = "utilityPlanGroupCollection"
        optionLabel         = "name"
        optionValue         = "value"
        placeholder         = "{{'@@SITE.WIZZARD.CONFIG.UTILITY_GROUP_PLACEHOLDER' | translate}}"
        [style]             = "{ width: '100%' }"
        (onChange)          = "processutilityPlanGroupSelection($event.value)">
        </p-dropdown>
    </div>

    <div class="col mr16">
      <div class="flex">
        <label>{{'@@SITE.WIZZARD.CONFIG.UTILITY_PLAN' | translate}} *</label>
        @if(state.utilityUrl)
        {
          <i style="cursor: pointer;" (click)="processPlanUrl()" pTooltip="More infor about the plan" class="pi pi-search"></i>
        }
      </div>
      <p-dropdown
        formControlName     = "utilityPlan"
        [class.is-invalid]  = "fu.isValid($form, 'utilityPlan')"
        [options]           = "utilityPlanCollection"
        optionLabel         = "name"
        optionValue         = "value"
        placeholder         = "{{'@@SITE.WIZZARD.CONFIG.UTILITY_PLAN_PLACEHOLDER' | translate}}"
        [style]             = "{ width: '100%' }"
        (onChange)          = "processUtilityPlanSelection($event.value)">
        </p-dropdown>
    </div>

  </div>


  <template-form-header [inputIcon]="'dollar'"
    [inputTitle]="'@@SITE.WIZZARD.CONFIG.PRICING_DETAILS'">
  </template-form-header>


  <div class="mt16 flex">

    <div class="col mr16">
      <label>{{'@@SITE.WIZZARD.CONFIG.MONTHLY_SUBSCRIPTION' | translate}} *</label>
      <input
            [class.is-invalid]  = "fu.isValid($form, 'monthlySubscription')"
            formatCurrency
            formControlName     = "monthlySubscription"
            class               = "input"/>
            <cc-form-error [inputForm]="$form" [inputField]="'state'"></cc-form-error>
    </div>

    <div class="col mr16">
      <label>{{'@@SITE.WIZZARD.CONFIG.DRIVER_PRICE_MULTIPLIER' | translate}} *</label>
      <input
            [class.is-invalid]  ="fu.isValid($form, 'driverPriceMultiplier')"
            formControlName     = "driverPriceMultiplier"
            class               = "input"
            type                = "number"
            min                 = "1"
            (blur)              = "processDriverPriceMultiplierChange()"
            (change)            = "processDriverPriceMultiplierChange()"/>
            <cc-form-error [inputForm]="$form" [inputField]="'driverPriceMultiplier'"></cc-form-error>
    </div>

    <div class="col mr16"></div>
  </div>

  <template-form-header [inputIcon]="'wallet'"
    [inputTitle]="'@@SITE.WIZZARD.CONFIG.INVESTMENT_DETAILS'">
  </template-form-header>


  <div class="mt16 flex">

    <div class="col mr16">
      <label>{{'@@SITE.WIZZARD.CONFIG.UTILIZATION' | translate}}</label>
      <input
            [class.is-invalid]  = "fu.isValid($form, 'utilization')"
            formControlName     = "utilization"
            class               = "input"
            type                = "number"
            min                 = "1"
            max                 = "100"/>
    </div>

    <div class="col mr16">
      <label>{{'@@SITE.WIZZARD.CONFIG.INCENTIVES' | translate}} </label>
      <input
            [class.is-invalid]  = "fu.isValid($form, 'incentives')"
            formatCurrency
            formControlName     = "incentives"
            class               = "input"/>
            <cc-form-error [inputForm]="$form" [inputField]="'incentives'"></cc-form-error>
    </div>

    <div class="col mr16" style="margin-top: 30px; display: flex;">
      <p-toggleButton
        formControlName = "hasIncentives30c"
        onLabel         = "30C applied"
        offLabel        = "30C Incentive"
        onIcon          = "pi pi-check"
        offIcon         = "pi pi-times"
         />

      <p-toggleButton
        (onChange)      = "processBabaConfiguration($event)"
        class           = "ml8"
        formControlName = "hasIncentivesBABA"
        onLabel         = "BABA applied"
        offLabel        = "BABA act"
        onIcon          = "pi pi-check"
        offIcon         = "pi pi-times"
        />
    </div>
  </div>

  <div class="mt16 flex">
    <div class="col mr16">
      <label>
        @if($form.get('isHardwareCostManuallyChanged')?.value)
        {
          <div class="mr4" style="color: #ff0000; display: inline-block;">
            <i pTooltip="The ammount is not affected by Hardware configuration change." class="pi pi-lock"></i>
          </div>

        }
        {{'@@SITE.WIZZARD.CONFIG.HARDWARE_COST' | translate}}
      </label>
      <div style="position: relative;">
        <input
              [class.is-invalid]  = "fu.isValid($form, 'hardwareCost')"
              formatCurrency
              (change)            = "lockHardwareCost()"
              formControlName     = "hardwareCost"
              class               = "input">
              @if($form.get('isHardwareCostManuallyChanged')?.value)
              {
                <div (click)="unlockHardwareCost()" class="input-icon">
                  <i class="pi pi-lock-open"></i>
                  MSRP
                </div>
              }
      </div>
      <cc-form-error [inputForm]="$form" [inputField]="'state'"></cc-form-error>
    </div>

    <div class="col mr16">
      <label>
        @if($form.get('isInstallationCostManuallyChanged')?.value)
        {
          <div class="mr4" style="color: #ff0000; display: inline-block;">
            <i pTooltip="The ammount is not affected by Hardware configuration change." class="pi pi-lock"></i>
          </div>
        }
        {{'@@SITE.WIZZARD.CONFIG.INSTALLATION_COST' | translate}}
      </label>
      <div style="position: relative;">
        <input
              [class.is-invalid]  = "fu.isValid($form, 'installationCost')"
              formatCurrency
              (change)            = "lockInstallationCost()"
              formControlName     = "installationCost"
              class               = "input"/>

              @if($form.get('isInstallationCostManuallyChanged')?.value)
              {
                <div (click)="unlockInstallationCost()" class="input-icon">
                  <i class="pi pi-lock-open"></i>
                  Std Cost
                </div>
              }
      </div>
      <cc-form-error [inputForm]="$form" [inputField]="'country'"></cc-form-error>
    </div>

    <div class="col mr16">
      <label>{{'@@SITE.WIZZARD.CONFIG.OPERATING_COST_PER_MONTH' | translate}} *</label>

      <input
            [class.is-invalid]  = "fu.isValid($form, 'operatingCost')"
            formatCurrency
            class               = "input"
            formControlName     = "operatingCost"/>
            <cc-form-error [inputForm]="$form" [inputField]="'operatingCost'"></cc-form-error>
    </div>
  </div>
