@if (inputIsVisible)
{
    <div class="card-box" style="padding: 16px;">

        <div>
            <i class="pi pi-car fs32 m5"></i>
        </div>

        <div class="ml16 mt8">
            <!-- Title Row -->
            <div class="just-flex" style="align-items: center;">
                <span class="ml8" style="font-size: 18px; font-weight: 600;">
                    Traffic for this location
                </span>
            </div>

            <div class="ml16 mt8">
                <!-- Expected weekly visitors -->
                <div class="just-flex mt8" style="align-items: center;">
                    <div style="width: 200px;">TAM(Total Addressable Market)</div>
                    <i style="font-size:12px;" class="pi pi-angle-double-right mt8 ml8 mr8"></i>
                    <div style="font-size:18px;">
                        {{ inputData?.roiAvgWeeklyVisits }}
                    </div>
                </div>

                <!-- Handled weekly visitors -->
                <div class="just-flex mt8" style="align-items: center;">
                    <div style="width: 200px;">Handled weekly visitors</div>
                    <i style="font-size:12px;" class="pi pi-angle-double-right mt8 ml8 mr8"></i>
                    <div style="font-size:18px;">
                    {{ inputData?.avgHandledWeeklyVisits }}
                    </div>
                </div>

                <!-- Travel traffic: 0-30 Miles -->
                <div class="just-flex mt8" style="align-items: center;">
                    <div style="width: 200px;">Travel traffic: 0-30 Miles</div>
                    <i style="font-size:12px;" class="pi pi-angle-double-right mt8 ml8 mr8"></i>
                    <div style="font-size:18px;">
                        {{ inputData?.trafficThirtyMiles }}
                    </div>
                </div>

                <!-- Travel traffic: 30-70 Miles -->
                <div class="just-flex mt8" style="align-items: center;">
                    <div style="width: 200px;">Travel traffic: 30-70 Miles</div>
                    <i style="font-size:12px;" class="pi pi-angle-double-right mt8 ml8 mr8"></i>
                    <div style="font-size:18px;">
                        {{ inputData?.trafficSeventyMiles }}
                    </div>
                </div>

                <!-- Travel traffic: >70 Miles -->
                <div class="just-flex mt8" style="align-items: center;">
                    <div style="width: 200px;">Travel traffic: >70 Miles</div>
                    <i style="font-size:12px;" class="pi pi-angle-double-right mt8 ml8 mr8"></i>
                    <div style="font-size:18px;">
                        {{ inputData?.trafficAboveSeventy }}
                    </div>
                </div>

            </div><!-- /margin-top -->
        </div>
    </div><!-- /card-box -->
}
@else
{
    <!-- If not visible, show placeholder -->
    <div class="cc-placeholder">
        <i class="pi pi-dollar fs32 m5"></i>
        <div class="ml16 fs24">
            Traffic for this location
        </div>
    </div>
}
