import {  inject            ,
          Injectable        } from '@angular/core';
import {  HttpEvent         ,
          HttpInterceptor   ,
          HttpHandler       ,
          HttpRequest       ,
          HttpErrorResponse } from '@angular/common/http';
import {  Observable        } from 'rxjs';
import {  catchError        ,
          finalize          } from 'rxjs/operators';
import { LoaderService      } from 'nv@services/loader.service';
import { MsalService } from '@azure/msal-angular';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

  private loaderService: LoaderService = inject(LoaderService);

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    this.loaderService.showLoader();

    return next.handle(req)
    .pipe(
      catchError((err: HttpErrorResponse) => {

        if(err) {
          this.loaderService.showErrorModal(err.error);
        }

        throw err;
      })
    )
    .pipe(
      finalize(() => this.loaderService.hideLoader())
    );
  }
}

@Injectable()
export class MetaHidrationInterceptor implements HttpInterceptor {

  private $auth = inject(MsalService);

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const username          = this.$auth.instance?.getActiveAccount()?.username ?? 'SYSTEM';
    const url               = req.url;
    const urlWithParameters = req.urlWithParams;
    const endpoint          = new URL(url).pathname;

    const authReq           = req.clone({
      headers: req.headers.set('Konnect-User'       , username)
                          .set("Konnect-Url"        , url)
                          .set("Konnect-UrlParams"  , urlWithParameters)
                          .set("Konnect-Endpoint"   , endpoint)
  });

    return next.handle(authReq);
  }
}
