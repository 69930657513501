{
  "name": "konect",
  "version": "1.20241115.1",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --configuration=local",
    "build": "ng build",
    "build_uat": "ng build --configuration=uat",
    "build_dev": "ng build --configuration=dev",
    "watch": "ng build --watch --configuration development",
    "test": "ng test"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.0.0",
    "@angular/common": "^18.0.0",
    "@angular/compiler": "^18.0.0",
    "@angular/core": "^18.0.0",
    "@angular/forms": "^18.0.0",
    "@angular/platform-browser": "^18.0.0",
    "@angular/platform-browser-dynamic": "^18.0.0",
    "@angular/router": "^18.0.0",
    "@asymmetrik/ngx-leaflet": "^18.0.1",
    "@azure/msal-angular": "^3.0.23",
    "@azure/msal-browser": "^3.22.0",
    "azure-maps-control": "^3.4.0",
    "font-awesome": "^4.7.0",
    "leaflet": "^1.9.4",
    "primeicons": "^7.0.0",
    "primeng": "^17.18.7",
    "rxjs": "~7.8.0",
    "tslib": "^2.3.0",
    "zone.js": "~0.14.2"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.0.7",
    "@angular/cli": "^18.0.7",
    "@angular/compiler-cli": "^18.0.0",
    "@types/jasmine": "~5.1.0",
    "@types/leaflet": "^1.9.12",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "typescript": "~5.4.2"
  }
}
