<div class="panel">Are you sure you want to delete <span style="font-weight: bold;">{{inputData.name}}</span>? <br>This action cannot be undone. All data associated with this project will be permanently removed.</div>

<div class="flex mt16" style="justify-content: flex-end;">
  <div (click)="onCancel.emit()" class="btn mr16">
    <i class="pi pi-times"></i> Cancel
  </div>
  <div (click)="onDelete.emit()" class="btn btn-danger">
    <i class="pi pi-trash"></i> Delete project
  </div>
</div>
