import {  CommonModule  } from '@angular/common';
import {  Component     ,
          Input         } from '@angular/core';
import {  TranslatePipe } from 'nv@services/translate.service';

@Component({
  selector    : 'template-travel-traffic',
  templateUrl : './template-travel-traffic.component.html',
  imports     : [ CommonModule, TranslatePipe ]
})
export class TemplateTravelTraffic {

  @Input() public inputData: any;
  @Input() public inputIsVisible = false
}